import axios from "axios";
import { BASE_URL } from "utils/constants";
import { getAuthToken } from "utils/auth_helpers";

export const startGame: (lobbyId: string) => Promise<string | null> = (
  lobbyId
) => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.get["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${getAuthToken()}`;

  return axios
    .post(`/api/game-lobby/${lobbyId}/start`, {})
    .then((res) => {
      // TODO - return game info
      return res.data._id as string;
    })
    .catch((err) => {
      console.log("Failure");
      console.log(err);
      return null;
    });
};
