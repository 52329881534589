import moneyImage from "assets/money_sm.jpg";
import steelImage from "assets/steel_sm.jpg";
import titaniumImage from "assets/titanium_sm.jpg";
import plantImage from "assets/plant_sm.jpg";
import powerImage from "assets/power_sm.jpg";
import heatImage from "assets/heat_sm.jpg";
import cardImage from "assets/card_sm.jpg";

export const playerColorToHex = (color: string) => {
  return playerColorToHexMap[color] ?? DEFAULT_GREY;
};

const playerColorToHexMap: { [key: string]: string } = {
  red: "#a22727",
  yellow: "#ada434",
  blue: "#2775b6",
  green: "#4ea443",
  purple: "#6a249c",
  gray: "#afaaaa",
};

export const playerColorToBackgroundHex = (color: string) => {
  return playerColorToBackgroundHexMap[color] ?? DEFAULT_GREY;
};

const playerColorToBackgroundHexMap: { [key: string]: string } = {
  red: "#d9575c",
  yellow: "#d9d574",
  blue: "#6ea6d9",
  green: "#9fd98a",
  purple: "#b475d9",
  gray: "#afaaaa",
};

const resourceTypeToResourceImageMap: { [key: string]: string } = {
  money: moneyImage,
  steel: steelImage,
  titanium: titaniumImage,
  plant: plantImage,
  power: powerImage,
  heat: heatImage,
  card: cardImage,
};

export const resourceTypeToResourceImage = (resourceType: string) => {
  return resourceTypeToResourceImageMap[resourceType];
};

export const DEFAULT_GREY = "#666666";
export const DEFAULT_BLACK = "#000000de";
export const TRANSPARENT_COLOR = "#00000000";
