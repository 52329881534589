import { useEffect } from "react";
import { verifyAccount } from "api/user/verify";
import { Card, CardContent, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

export const Component: React.FC<{}> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const key = query.get("key");

  useEffect(() => {
    if (key) {
      verifyAccount({
        key,
        enqueueSnackbar,
      }).then(() => {
        navigate("/login");
      });
    }
  }, [key, enqueueSnackbar, navigate]);

  return (
    <Card
      className="AccountVerificationPage"
      sx={{ maxWidth: 345, m: "auto", mt: 5 }}
    >
      <CardContent>
        <Typography variant="h4">Verifying Account...</Typography>
      </CardContent>
      <CardContent>
        <Typography>You should be redirected shortly</Typography>
      </CardContent>
    </Card>
  );
};

Component.displayName = "AccountVerificationPage";
