import React, { useContext } from "react";
import { ChooseEffectOptionType } from "api/game/GameType";
import { Box, Card, Typography, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { GameContext } from "components/pages/game/GamePage";
import { executeGameAction } from "api/game/gameActions";
import { useSnackbar } from "notistack";

export interface Props {
  options: ChooseEffectOptionType[];
}

export const Component: React.FC<Props> = ({ options }) => {
  const { gameId } = useParams();
  const { setGame } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();

  const onClick = (option: number) => {
    if (gameId === undefined) {
      console.error("Game ID is undefined");
      return;
    }

    executeGameAction({
      gameId,
      ActionData: {
        option,
      },
      ActionId: "SELECT_DECISION",
      DecisionId: "TILE_PLACEMENT_DECISION",
      setGame,
      enqueueSnackbar,
    });
  };

  return (
    <Card
      sx={{
        borderRadius: 0,
        width: "fit-content",
        maxWidth: "100%",
        margin: "auto",
      }}
    >
      <Box sx={{ margin: 2 }}>
        <Typography variant="h5" gutterBottom>
          Choose Effect
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {options.map((option) => (
            <Button
              key={option.option}
              variant="contained"
              onClick={() => onClick(option.option)}
              sx={{ m: 1 }}
            >
              {option.description}
            </Button>
          ))}
        </Box>
      </Box>
    </Card>
  );
};

Component.displayName = "TilePlacementDecision";
