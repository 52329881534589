export type Map = (typeof Maps)[keyof typeof Maps];
export const Maps = {
  BASE: "Base",
  ELYSIUM: "Elysium",
  HELLAS: "Hellas",
  PLANUM_AUSTRALE: "Planum_Australe",
  SYRTIS_MAJOR: "Syrtis_Major",
} as const;

export const tileNameToImageName: { [key: string]: string } = {
  ARTIFICIAL_LAKE: "ocean",
  SOLAR_OCEAN: "ocean",
  CAPITAL: "capital",
  COMMERCIAL_DISTRICT: "commecial",
  ECOLOGICAL_ZONE: "nature",
  FLOOD_OCEAN: "ocean",
  GANYMEDE_COLONY: "city",
  INDUSTRIAL_CENTER: "factory",
  LAND_CLAIM: "empty",
  LAVA_FLOWS: "volcano",
  MINING_AREA: "mining",
  MINING_RIGHTS: "mining",
  MOHOLE_AREA: "mohole",
  NATURAL_PRESERVE: "preserve",
  NOCTIS_CITY: "city",
  NUCLEAR_ZONE: "nuclear",
  OCEAN_GREENERY: "greenery",
  PHOBOS_SPACE_HAVEN: "city",
  RESEARCH_OUTPOST: "city",
  RESTRICTED_AREA: "restricted",
  STANDARD_CITY: "city",
  STANDARD_GREENERY: "greenery",
  STANDARD_OCEAN: "ocean",
  URBANIZED_AREA: "city",
  MAXWELL_BASE: "city",
  STRATOPOLIS: "city",
  DAWN_CITY: "city",
  LUNA_METROPOLIS: "city",
};
export const STORAGE_BASE_URL =
  "https://tfmars-bucket.sfo3.digitaloceanspaces.com";

export const LOCALSTORAGE_TOKEN_KEY = "token";
export const BASE_URL = "https://www.tfmars.com";
// export const BASE_URL = "https://localhost:8081";
