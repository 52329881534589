import React, { useState, useContext } from "react";
import { Card, Button, Tooltip, Box, Typography } from "@mui/material";
import * as CardSelector from "components/pages/game/CardSelector";
import * as MulliganCorpModal from "components/pages/game/decision-modals/MulliganCorpModal";
import * as MulliganCardsModal from "components/pages/game/decision-modals/MulliganCardsModal";
import * as MulliganPreludeCardsModal from "components/pages/game/decision-modals/MulliganPreludeCardsModal";
import * as StartingHandModal from "components/pages/game/decision-modals/StartingHandModal";
import { CardType, GeneralPlayerType } from "api/game/GameType";
import { useParams } from "react-router-dom";
import { getGame } from "api/game/get-game";
import { resetGame } from "api/game/reset-game";
import { GameContext } from "components/pages/game/GamePage";
import { isCurrentPlayerSelectingStartingHand } from "utils/user_helpers";

export const Component: React.FC<{}> = () => {
  const { game, setGame } = useContext(GameContext);
  const { gameId } = useParams();
  const [selectedCards, setSelectedCards] = useState<CardType[]>([]);
  const [openActionModalName, setOpenActionModalName] = useState<string>("");

  const selectCard = (card: CardType) => {
    setSelectedCards((currentSelectedCards) => {
      if (currentSelectedCards.includes(card)) {
        return currentSelectedCards.filter((c) => c.name !== card.name);
      } else {
        return [...currentSelectedCards, card];
      }
    });
  };

  const reset = () => {
    if (gameId === undefined) {
      console.error("Game ID is undefined");
      return;
    }

    resetGame(gameId).then(() => {
      getGame(gameId).then((game) => {
        if (game === null) {
          console.error("Game is null");
          return;
        }
        setSelectedCards([]);
        setGame(game);
      });
    });
  };

  const maybeRenderMulliganCorpButton = (corps: CardType[]) => {
    if (corps.length < 2) {
      return null;
    }
    return (
      <Tooltip title="Discard your corporations and draw 1 new corporation">
        <Button
          sx={{ m: 1 }}
          variant="contained"
          color="warning"
          onClick={() => setOpenActionModalName("MULLIGAN_CORP")}
        >
          Mulligan Corporation
        </Button>
      </Tooltip>
    );
  };

  const maybeRenderMulliganCardsButton = (cards: CardType[]) => {
    if (cards.length < 3) {
      return null;
    }
    return (
      <Tooltip title="Discard your cards and draw 2 fewer cards">
        <Button
          sx={{ m: 1 }}
          variant="contained"
          color="warning"
          onClick={() => setOpenActionModalName("MULLIGAN_CARDS")}
        >
          Mulligan Cards
        </Button>
      </Tooltip>
    );
  };

  const maybeRenderMulliganPreludeCardsButton = (prelude: CardType[]) => {
    if (prelude.length < 3) {
      return null;
    }
    return (
      <Tooltip title="Discard your prelude cards and draw 1 fewer">
        <Button
          sx={{ m: 1 }}
          variant="contained"
          color="warning"
          onClick={() => setOpenActionModalName("MULLIGAN_PRELUDE_CARDS")}
        >
          Mulligan Prelude Cards
        </Button>
      </Tooltip>
    );
  };

  const renderSubmitCardsButton = () => {
    return (
      <Button
        sx={{ m: 1 }}
        variant="contained"
        color="primary"
        onClick={() => {
          setOpenActionModalName("INITIAL_PURCHASE_CARDS");
        }}
      >
        Submit ({selectedCards.filter((card) => !card.isCorporation).length}{" "}
        cards)
      </Button>
    );
  };

  const maybeRenderResetGameButton = (allPlayers: GeneralPlayerType[]) => {
    if (allPlayers.length > 1) {
      return null;
    }
    return (
      <Button
        sx={{ m: 1 }}
        variant="contained"
        color="error"
        className="m-4"
        onClick={reset}
      >
        Reset Game
      </Button>
    );
  };

  const renderStartingHand = () => {
    const prelude = game.player.consideredCards.filter(
      (card) => card.cardType === "PRELUDE"
    );
    const corps = game.player.consideredCards.filter(
      (card) => card.cardType === "CORPORATION"
    );
    const cards = game.player.consideredCards.filter(
      (card) =>
        card.cardType === "ACTIVE" ||
        card.cardType === "AUTOMATED" ||
        card.cardType === "EVENT"
    );
    return (
      <Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          <CardSelector.Component
            cards={corps}
            selectedCards={selectedCards}
            onToggleCard={(card) => selectCard(card)}
          />
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          <CardSelector.Component
            cards={prelude}
            selectedCards={selectedCards}
            onToggleCard={(card) => selectCard(card)}
          />
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          <CardSelector.Component
            cards={cards}
            selectedCards={selectedCards}
            onToggleCard={(card) => selectCard(card)}
          />
        </Box>
        <Box>
          {maybeRenderMulliganCorpButton(corps)}
          {maybeRenderMulliganCardsButton(cards)}
          {maybeRenderMulliganPreludeCardsButton(prelude)}
          {renderSubmitCardsButton()}
          {maybeRenderResetGameButton(game.allPlayers)}
        </Box>
      </Box>
    );
  };

  const renderStartingHandAndOptionButtons = () => {
    return (
      <Box>
        {renderStartingHand()}
        <MulliganCorpModal.Component
          isOpen={openActionModalName === "MULLIGAN_CORP"}
          onClose={() => {
            setOpenActionModalName("");
            setSelectedCards((currentSelectedCards) =>
              currentSelectedCards.filter((card) => !card.isCorporation)
            );
          }}
        />
        <MulliganCardsModal.Component
          isOpen={openActionModalName === "MULLIGAN_CARDS"}
          onClose={() => {
            setOpenActionModalName("");
            setSelectedCards((currentSelectedCards) =>
              currentSelectedCards.filter((card) => card.isCorporation)
            );
          }}
        />
        <MulliganPreludeCardsModal.Component
          isOpen={openActionModalName === "MULLIGAN_PRELUDE_CARDS"}
          onClose={() => {
            setOpenActionModalName("");
            setSelectedCards((currentSelectedCards) =>
              currentSelectedCards.filter((card) => card.isCorporation)
            );
          }}
        />
        <StartingHandModal.Component
          cards={selectedCards}
          isOpen={openActionModalName === "INITIAL_PURCHASE_CARDS"}
          onClose={() => setOpenActionModalName("")}
        />
      </Box>
    );
  };

  const renderWaitingOnOthersMessage = () => {
    return (
      <Card sx={{ margin: 2 }}>
        <Typography variant="h5" sx={{ mx: 2 }}>
          Waiting for other players to make their selections
        </Typography>
      </Card>
    );
  };

  return isCurrentPlayerSelectingStartingHand(game)
    ? renderStartingHandAndOptionButtons()
    : renderWaitingOnOthersMessage();
};

Component.displayName = "StartingHand";
