import axios from "axios";
import { GameLobbyType } from "api/game-lobby/get-game-lobby";
import { BASE_URL } from "utils/constants";
import { getAuthToken } from "utils/auth_helpers";

export const joinGameLobby: (
  lobbyId: string
) => Promise<GameLobbyType | null> = (lobbyId) => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.get["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${getAuthToken()}`;

  return axios
    .post(`/api/game-lobby/${lobbyId}/join`, {})
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Failure");
      console.log(err);
      return null;
    });
};
