import React from "react";
import { Paper, List, ListItem, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

interface Props {
  logContent: string;
}

interface LogEntry {
  date: Date | null;
  message: string;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0.25),
  backgroundColor: theme.palette.background.paper,
  maxHeight: "100%",
  overflow: "auto",
}));

const LogItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.5),
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:last-child": {
    borderBottom: "none",
  },
  fontSize: "0.85em",
}));

const Timestamp = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  paddingRight: theme.spacing(0.5),
  fontFamily: "monospace",
  whiteSpace: "nowrap",
  width: "35px",
  textAlign: "right",
  flexShrink: 0,
  fontSize: "0.75em",
}));

export const Component: React.FC<Props> = ({ logContent }) => {
  const renderSingleLogEntry = (entry: LogEntry) => {
    return (
      <LogItem disablePadding>
        <Box display="flex" width="100%" alignItems="flex-start" gap={1}>
          {entry.date ? renderTimestamp(entry.date) : null}
          <Typography variant="body2" sx={{ flexGrow: 1 }}>
            {entry.message}
          </Typography>
        </Box>
      </LogItem>
    );
  };

  const renderTimestamp = (date: Date) => {
    return (
      <Box display="flex" flexDirection="column">
        <Timestamp variant="body2">
          {`${String(date.getUTCHours()).padStart(2, "0")}:${String(
            date.getUTCMinutes()
          ).padStart(2, "0")}`}
        </Timestamp>
        <Timestamp variant="body2">
          {date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
          })}
        </Timestamp>
      </Box>
    );
  };

  const parseLogEntries = (content: string): LogEntry[] => {
    return content
      .split("\n")
      .filter((line) => line.trim())
      .map(parseLogEntry);
  };

  const parseLogEntry = (line: string): LogEntry => {
    const match = line.match(/\[(.*?)\] (.*)/);

    if (match && match.length > 2) {
      const timestamp = match[1];
      const message = match[2];
      return {
        date: parseTimestamp(timestamp),
        message: message,
      };
    }

    return {
      date: null,
      message: line,
    };
  };

  const parseTimestamp = (timestamp: string): Date | null => {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return null;
    }
    return date;
  };

  return (
    <StyledPaper elevation={2}>
      <List disablePadding>
        {parseLogEntries(logContent).map(renderSingleLogEntry)}
      </List>
    </StyledPaper>
  );
};

Component.displayName = "LogContainer";
