import React, { ReactNode } from "react";
import * as RightSidebar from "components/core/RightSidebar";
import { Box } from "@mui/material";

export interface Props {
  children: ReactNode;
  sidebarContent: ReactNode;
  sidebarWidth?: string | number;
  isOpen: boolean;
  onClose: () => void;
}

export const Component: React.FC<Props> = ({
  children,
  sidebarContent,
  isOpen,
  onClose,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          minHeight: "100vh",
        }}
      >
        {children}
      </Box>
      <RightSidebar.Component isOpen={isOpen} onClose={onClose}>
        {sidebarContent}
      </RightSidebar.Component>
    </Box>
  );
};

Component.displayName = "PageWithSidebar";
