import React, { useContext, useState } from "react";
import { Button, Box, Card } from "@mui/material";
import { CardType } from "api/game/GameType";
import { useParams } from "react-router-dom";
import { GameContext } from "components/pages/game/GamePage";
import * as CardSelector from "components/pages/game/CardSelector";
import { executeGameAction } from "api/game/gameActions";
import { useSnackbar } from "notistack";

export interface Props {
  cards: CardType[];
}

export const Component: React.FC<Props> = ({ cards }) => {
  const { gameId } = useParams();
  const { setGame } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCards, setSelectedCards] = useState<CardType[]>([]);

  const selectCard = (card: CardType) => {
    if (selectedCards.includes(card)) {
      setSelectedCards(selectedCards.filter((c) => c !== card));
    } else {
      setSelectedCards([...selectedCards, card]);
    }
  };

  const onSubmit = (cardsToSell: CardType[]) => {
    if (gameId === undefined) {
      console.error("Game ID is undefined");
      return;
    }

    executeGameAction({
      gameId,
      ActionData: {
        cards: cardsToSell.map((card) => card.name),
      },
      ActionId: "SELL_CARDS",
      DecisionId: "SELL",
      setGame,
      enqueueSnackbar,
    });
  };

  return (
    <Card
      sx={{
        borderRadius: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2>Choose Card(s) to Sell</h2>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "10px",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <CardSelector.Component
          cards={cards}
          selectedCards={selectedCards}
          onToggleCard={selectCard}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={() => onSubmit([])}
          sx={{ m: 1, width: "200px" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => onSubmit(selectedCards)}
          sx={{ m: 1, width: "200px" }}
        >{`Sell ${selectedCards.length}`}</Button>
      </Box>
    </Card>
  );
};

Component.displayName = "SellCards";
