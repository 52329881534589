import React, { useContext } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { GameContext } from "components/pages/game/GamePage";
import { executeGameAction } from "api/game/gameActions";
import { useSnackbar } from "notistack";

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  content: React.ReactNode;
  ActionData?: any;
  ActionId: string;
  DecisionId: string;
}

export const Component: React.FC<Props> = ({
  isOpen,
  onClose,
  content,
  ActionData,
  ActionId,
  DecisionId,
}) => {
  const { gameId } = useParams();
  const { setGame } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();

  const onConfirm = () => {
    if (gameId === undefined) {
      console.error("Game ID is undefined");
      return;
    }

    executeGameAction({
      gameId,
      ActionData,
      ActionId,
      DecisionId,
      setGame,
      enqueueSnackbar,
    }).then(() => {
      onClose();
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="confirm-decision-title"
    >
      <DialogTitle id="confirm-decision-title">Confirm Decision</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Decline
        </Button>
        <Button onClick={() => onConfirm()} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Component.displayName = "ConfirmDecision";
