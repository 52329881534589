import { CardType, SelfPlayerType } from "api/game/GameType";

export const hasFundingOptions = ({
  card,
  player,
}: {
  card?: CardType;
  player: SelfPlayerType;
}): boolean => {
  return (
    canUseTitanium({ card, player }) ||
    canUseSteel({ card, player }) ||
    canUseHeat(player) ||
    canUseDirigibles({ player, card })
  );
};

export const canUseTitanium = ({
  card,
  player,
}: {
  card?: CardType;
  player: SelfPlayerType;
}): boolean => {
  if (!card) {
    return false;
  }
  return player.resources.titanium > 0 && card.tags.includes("SPACE");
};

export const canUseSteel = ({
  card,
  player,
}: {
  card?: CardType;
  player: SelfPlayerType;
}): boolean => {
  if (!card) {
    return false;
  }
  return player.resources.steel > 0 && card.tags.includes("BUILDER");
};

export const canUseHeat = (player: SelfPlayerType): boolean => {
  return player.canUseHeatForMoney && player.resources.heat > 0;
};

export const canUseDirigibles = ({
  card,
  player,
}: {
  card?: CardType;
  player: SelfPlayerType;
}): boolean => {
  if (!card) {
    return false;
  }
  return getNumberOfDirigibles(player) > 0 && card.tags.includes("VENUS");
};

export const getNumberOfDirigibles = (player: SelfPlayerType): number => {
  const dirigiblesInfo = player.cardsOnBoard.filter(
    (card) => card.name === "Dirigibles"
  );
  return dirigiblesInfo.length > 0 ? dirigiblesInfo[0].counters : 0;
};
