import { useState } from "react";
import { resetPassword } from "api/user/reset-password";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

export const Component: React.FC<{}> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const key = query.get("key");
  const [password, setPassword] = useState<string>("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const updatedPassword = event.currentTarget.value;
    setPassword(updatedPassword);
    if (updatedPassword.length > 0) {
      setPasswordErrorMessage("");
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!key) {
      enqueueSnackbar("Please use the link that was emailed to you.", {
        variant: "error",
      });
      return;
    }

    if (password.length < 6) {
      setPasswordErrorMessage("Password must be at least 6 characters");
      return;
    }

    setIsLoading(true);
    resetPassword({
      key,
      password,
      enqueueSnackbar,
    })
      .then(() => {
        navigate("/login");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card
      className="PasswordResetPage"
      sx={{ maxWidth: 345, m: "auto", mt: 5 }}
    >
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Login
        </Typography>
        <form onSubmit={onSubmit}>
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => onPasswordInputChange(e)}
            fullWidth
            margin="normal"
            error={passwordErrorMessage !== ""}
            helperText={passwordErrorMessage}
          />
          <Typography
            variant="body2"
            sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}
          ></Typography>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Button type="submit" variant="contained" sx={{ mt: 2 }} fullWidth>
              Submit
            </Button>
          )}
        </form>
      </CardContent>
    </Card>
  );
};

Component.displayName = "PasswordResetPage";
