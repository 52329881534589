const tileNameToRequirementMap: { [key: string]: string } = {
  ARTIFICIAL_LAKE: "Must be placed on a non-Ocean area",
  CAPITAL: "",
  COMMERCIAL_DISTRICT: "",
  ECOLOGICAL_ZONE: "Must be placed adjacent to a greenery",
  FLOOD_OCEAN: "Must be placed on a slot reserved for oceans",
  GANYMEDE_COLONY: "Must be placed on the Ganymede Colony area",
  INDUSTRIAL_CENTER: "Must be placed adjacent to a city",
  LAND_CLAIM: "",
  LAVA_FLOWS: "Must be placed on a mountain tile.",
  MINING_AREA: "Must be placed adjacent to one of your tiles",
  MINING_RIGHTS: "",
  MOHOLE_AREA: "Must be placed on an area reserved for ocean",
  NATURAL_PRESERVE: "Must be placed next to no other tile",
  NOCTIS_CITY: "Must be placed on the Noctis City location.",
  NUCLEAR_ZONE: "",
  OCEAN_GREENERY: "Must be placed on an area reserved for ocean",
  PHOBOS_SPACE_HAVEN: "Must be placed on the Phobos Space Haven area",
  RESEARCH_OUTPOST: "Must be placed next to no other tile",
  RESTRICTED_AREA: "",
  STANDARD_CITY: "Must not be placed adjacent to another city",
  STANDARD_GREENERY: "Must be placed adjacent to one of your tiles if possible",
  STANDARD_OCEAN: "Must be placed on an area reserved for ocean",
  URBANIZED_AREA: "Must be placed adjacent to two city tiles",
};

export const tileNameToRequirement = (actionName: string) => {
  return tileNameToRequirementMap[actionName] ?? "";
};

const actionIdToDescriptionMap: { [key: string]: string } = {
  STANDARD_CITY: "Standard Action City",
  STANDARD_GREENERY: "Standard Action Greenery",
  STANDARD_OCEAN: "Standard Action Aquifer (ocean)",
  STANDARD_POWER: "Standard Action Power Plant",
  STANDARD_TEMP: "Standard Action Asteroid (temp)",
  STANDARD_VENUS: "Standard Action Air Scrappers (venus)",
};

export const actionIdToDescription = (actionId: string) => {
  return actionIdToDescriptionMap[actionId] ?? `ActionId: ${actionId}`;
};
