import React, { useContext } from "react";
import { ChooseAttackOptionType } from "api/game/GameType";
import { Box, Button, Card, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { GameContext } from "components/pages/game/GamePage";
import { executeGameAction } from "api/game/gameActions";
import { useSnackbar } from "notistack";
import {
  playerColorToHex,
  playerColorToBackgroundHex,
} from "utils/styling_helpers";

export interface Props {
  options: ChooseAttackOptionType[];
}

export const Component: React.FC<Props> = ({ options }) => {
  const { gameId } = useParams();
  const { setGame } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();

  const onClick = (target: string) => {
    if (gameId === undefined) {
      console.error("Game ID is undefined");
      return;
    }
    executeGameAction({
      gameId,
      ActionData: {
        target,
      },
      ActionId: "ATTACK",
      DecisionId: "CHOOSE_ATTACK_TARGET",
      setGame,
      enqueueSnackbar,
    });
  };

  return (
    <Card
      sx={{
        borderRadius: 0,
      }}
    >
      <Typography variant="h5">Choose Attack Target:</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {options.map((option) => (
          <Button
            onClick={() => onClick(option.option)}
            sx={{
              backgroundColor: playerColorToHex(option.color),
              m: 1,
              color: "white",
              "&:hover": {
                backgroundColor: playerColorToBackgroundHex(option.color),
              },
            }}
          >
            {option.description}
          </Button>
        ))}
      </Box>
    </Card>
  );
};

Component.displayName = "ChooseAttackTarget";
