import axios from "axios";
import { BASE_URL } from "utils/constants";
import { getAuthToken } from "utils/auth_helpers";

export const createLobby = ({
  name,
  password,
}: {
  name: string;
  password?: string;
}) => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.get["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${getAuthToken()}`;

  return axios
    .post("/api/game-lobby/new", {
      name,
      password,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Failure");
      console.log(err);
      return [];
    });
};
