import axios from "axios";
import jwt_decode from "jwt-decode";
import { BASE_URL } from "utils/constants";
import { setAuthToken } from "utils/auth_helpers";
import { CurrentUser } from "../../App";
import { NotifierFunctionType } from "api/common/notifier";

function login({
  email,
  password,
  enqueueSnackbar,
}: {
  email: string;
  password: string;
  enqueueSnackbar: NotifierFunctionType;
}): Promise<CurrentUser | null> {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";

  return axios
    .post("/api/user/login", {
      email,
      password,
    })
    .then((res) => {
      const { token } = res.data;
      setAuthToken(token);
      const decoded_token: CurrentUser = jwt_decode(token);
      return decoded_token;
    })
    .catch((error) => {
      if (error.response?.data?.message) {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("An error occurred", { variant: "error" });
      }
      return null;
    });
}

export default login;
