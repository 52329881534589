import axios from "axios";
import { BASE_URL } from "utils/constants";
import { getAuthToken } from "utils/auth_helpers";

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.get["Content-Type"] = "application/json";

export type Game = {
  _id: string;
  name: string;
  players: Player[];
  currentDecisions: Player[];
};

export type Player = {
  _id: string;
  UserId: string;
  name: string;
};

export const getActiveGames = () => {
  const token = getAuthToken();
  axios.defaults.headers.get["Authorization"] = `Bearer ${token}`;

  return axios
    .get("/api/active-games")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Failure");
      console.log(err);
      return null;
    });
};
