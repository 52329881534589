import axios from "axios";
import { BASE_URL } from "utils/constants";
import { getAuthToken } from "utils/auth_helpers";

export type CompletedGame = {
  _id: string;
  name: string;
  players: Player[];
};

// TODO - consolidate with type in get-active-game
export type Player = {
  _id: string;
  UserId: string;
  name: string;
};

export const getCompletedGames: () => Promise<CompletedGame[] | null> = () => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${getAuthToken()}`;

  return axios
    .get("/api/active-games/complete")
    .then((res) => {
      return res.data as CompletedGame[];
    })
    .catch((err) => {
      console.log("Failure");
      console.log(err);
      return null;
    });
};
