import React, { useContext } from "react";
import { Box, Button, Card } from "@mui/material";
import { useParams } from "react-router-dom";
import { GameContext } from "components/pages/game/GamePage";
import * as GameCard from "components/pages/game/GameCard";
import { executeGameAction } from "api/game/gameActions";
import { useSnackbar } from "notistack";
export interface Props {
  cardName: string;
  tooltip: string;
}

export const Component: React.FC<Props> = ({ cardName, tooltip }) => {
  const { gameId } = useParams();
  const { setGame } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();

  const onClick = (cards: string[]) => {
    if (gameId === undefined) {
      console.error("Game ID is undefined");
      return;
    }

    executeGameAction({
      gameId,
      ActionData: {
        cards,
      },
      ActionId: "CHOOSE_PURCHASE_CARDS",
      DecisionId: "PURCHASE_CARD_DECISION",
      setGame,
      enqueueSnackbar,
    });
  };

  return (
    <Card
      sx={{
        borderRadius: 0,
      }}
    >
      <h2>Purchase this card?</h2>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <GameCard.Component name={cardName} tooltip={tooltip} />
        <Button variant="contained" sx={{ m: 1 }} onClick={() => onClick([])}>
          Discard
        </Button>
        <Button
          variant="contained"
          sx={{ m: 1 }}
          onClick={() => onClick([cardName])}
        >
          Purchase
        </Button>
      </Box>
    </Card>
  );
};

Component.displayName = "PurchaseCardDecision";
