import React from "react";
import { Typography } from "@mui/material";
import * as GameCard from "components/pages/game/GameCard";
import * as FundActionModal from "components/pages/game/decision-modals/FundActionModal";
import { CardType } from "api/game/GameType";

export interface Props {
  card: CardType;
  cardTooltip: string;
  isOpen: boolean;
  onClose: () => void;
}

export const Component: React.FC<Props> = ({
  card,
  cardTooltip,
  isOpen,
  onClose,
}) => {
  const renderContent = () => {
    return (
      <div>
        <Typography variant="body1">
          Confirm you want to play <strong>{card.name}</strong>.
        </Typography>
        <GameCard.Component name={card.name} tooltip={cardTooltip} />
      </div>
    );
  };

  return (
    <FundActionModal.Component
      content={renderContent()}
      isOpen={isOpen}
      onClose={onClose}
      card={card}
      ActionId="PLAY_CARD"
      DecisionId="MAIN_ACTION"
    />
  );
};

Component.displayName = "PlayCardModal";
