import React from "react";
import { styled } from "@mui/system";
import { TapOptionType } from "api/game/GameType";
import { getCardImageUrl } from "utils/card_helpers";
import {
  Box,
  Typography,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";

interface Props {
  name: string;
  isCorporation?: boolean;
  isPrelude?: boolean;
  tooltip?: string;
  disabled?: boolean;
  hasBeenTapped?: boolean;
  counters?: number;
  counterType?: string;
  tapOptions?: TapOptionType[];
  onClick?: () => void;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#656565",
    color: "white",
    maxWidth: 220,
    fontSize: "12px",
    border: "1px solid #dadde9",
  },
});

export const Component: React.FC<Props> = ({
  name,
  tooltip,
  isCorporation = false,
  isPrelude = false,
  disabled = false,
  hasBeenTapped,
  counters,
  counterType,
  tapOptions,
  onClick,
}) => {
  return (
    <Box sx={{ padding: "3px" }}>
      <CustomTooltip title={tooltip || ""} placement="top">
        <Box
          sx={{
            width: isCorporation || isPrelude ? 214 : 110,
            position: "relative",
            opacity: disabled ? 0.5 : 1,
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => {
            if (onClick) onClick();
          }}
        >
          <img src={getCardImageUrl(name)} width="100%" alt={name} />
          <Box
            sx={{
              position: "absolute",
              bottom: 8,
              left: 0,
              right: 0,
              textAlign: "center",
              backgroundColor: "#656565",
              p: {
                textTransform: "capitalize",
                m: 0,
                color: "white",
                fontSize: "12px",
                padding: "0px",
              },
            }}
          >
            {counterType && (
              <Typography component="p" variant="caption">
                {counterType.toLowerCase()}: {counters}
              </Typography>
            )}
            {hasBeenTapped && (
              <Typography component="p" variant="caption">
                <em>Tapped</em>
              </Typography>
            )}
            {!hasBeenTapped && tapOptions && tapOptions.length > 0 && (
              <Typography component="p" variant="caption">
                <em style={{ color: "#bcfafd" }}>Untapped</em>
              </Typography>
            )}
          </Box>
        </Box>
      </CustomTooltip>
    </Box>
  );
};

Component.displayName = "GameCard";
