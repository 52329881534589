import React, { useState, useContext } from "react";
import { Button, Card, Typography, Box } from "@mui/material";
import * as CardSelector from "components/pages/game/CardSelector";
import { CardType } from "api/game/GameType";
import * as PurchaseCardsModal from "components/pages/game/decision-modals/PurchaseCardsModal";
import { isCurrentPlayerDrafting } from "utils/user_helpers";
import { GameContext } from "components/pages/game/GamePage";

interface Props {}

export const Component: React.FC<Props> = () => {
  const { game } = useContext(GameContext);

  const [selectedCards, setSelectedCards] = useState<CardType[]>([]);
  const [openActionModal, setOpenActionModal] = useState<boolean>(false);

  const renderDraftCards = () => {
    return (
      <Card sx={{ margin: 2 }}>
        <Typography variant="h5" sx={{ mx: 2 }}>
          Cards available for Purchase
        </Typography>
        <CardSelector.Component
          selectedCards={selectedCards}
          cards={game.player.consideredCards}
          onToggleCard={(card: CardType) => {
            if (selectedCards.includes(card)) {
              setSelectedCards(selectedCards.filter((c) => c !== card));
            } else {
              setSelectedCards([...selectedCards, card]);
            }
          }}
        />
        <Box sx={{ textAlign: "center", my: 2 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setOpenActionModal(true)}
          >
            Purchase Cards ({selectedCards.length})
          </Button>
        </Box>
        <PurchaseCardsModal.Component
          cards={selectedCards}
          isOpen={openActionModal}
          onClose={() => setOpenActionModal(false)}
        />
      </Card>
    );
  };

  const renderWaitingOnOthersMessage = () => {
    return (
      <Card sx={{ margin: 2 }}>
        <Typography variant="h5" sx={{ mx: 2 }}>
          Waiting for other players to make their selections
        </Typography>
      </Card>
    );
  };

  return isCurrentPlayerDrafting(game)
    ? renderDraftCards()
    : renderWaitingOnOthersMessage();
};

Component.displayName = "DraftCards";
