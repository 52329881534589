import React from "react";
import * as DecisionBaseModal from "components/pages/game/decision-modals/DecisionBaseModal";
import { Typography, Box } from "@mui/material";
import { CardType } from "api/game/GameType";
import * as GameCard from "components/pages/game/GameCard";

export interface Props {
  cards: CardType[];
  isOpen: boolean;
  onClose: () => void;
}

export const Component: React.FC<Props> = ({ cards, isOpen, onClose }) => {
  const cardNames = (): string[] => {
    return cards.map((c) => c.name);
  };

  const renderContent = () => {
    return (
      <Box>
        <Typography variant="body1">
          Confirm this is your desired starting hand.
        </Typography>
        <Box>
          {cards
            .filter((card) => card.isCorporation)
            .map((card) => (
              <Box key={card.name} className="card-container">
                <GameCard.Component
                  name={card.name}
                  isCorporation={card.isCorporation}
                  isPrelude={card.cardType === "PRELUDE"}
                  tooltip={card.tooltip}
                />
              </Box>
            ))}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          {cards
            .filter((card) => !card.isCorporation)
            .map((card) => (
              <Box key={card.name} className="card-container">
                <GameCard.Component
                  name={card.name}
                  isCorporation={card.isCorporation}
                  isPrelude={card.cardType === "PRELUDE"}
                  tooltip={card.tooltip}
                />
              </Box>
            ))}
        </Box>
      </Box>
    );
  };

  return (
    <DecisionBaseModal.Component
      content={renderContent()}
      isOpen={isOpen}
      onClose={onClose}
      ActionData={{
        selectedCards: cardNames(),
      }}
      ActionId="INITIAL_PURCHASE_CARDS"
      DecisionId="STARTING_HAND"
    />
  );
};

Component.displayName = "StartingHandModal";
