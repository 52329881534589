import React, { ReactNode } from "react";
import { Box, Typography, Card, CardContent, Tooltip } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { ResourceAmountsType } from "api/game/GameType";
import {
  playerColorToHex,
  resourceTypeToResourceImage,
} from "utils/styling_helpers";
import { GameType } from "api/game/GameType";

export interface Props {
  game: GameType;
  name: string;
  isCurrentTurn: boolean;
  hasPassedForGen: boolean;
  resources: ResourceAmountsType;
  production: ResourceAmountsType;
  events: number;
  terraformRating: number;
  turnOrder: number;
  playerColor: string;
  numberOfCardsInHand: number;
  cardPoints: number;
  totalTradeFleets: number;
  userId: string;
}

const TurnIndicator = styled("div")<{
  turnState: {
    hasPassedForGen: boolean;
    isCurrentTurn: boolean;
  };
}>(({ theme, turnState }) => {
  let backgroundColor;
  let color;
  const { hasPassedForGen, isCurrentTurn } = turnState;
  if (hasPassedForGen) {
    backgroundColor = "gray";
    color = "white";
  } else if (isCurrentTurn) {
    backgroundColor = "green";
    color = "white";
  } else {
    backgroundColor = "transparent";
    color = "black";
  }

  return {
    backgroundColor,
    color,
    padding: theme.spacing(0.5),
    textAlign: "center",
  };
});

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  "&:hover": {
    boxShadow: theme.shadows[10],
  },
  borderRadius: theme.shape.borderRadius,
  display: "inline-block",
  margin: theme.spacing(2),
}));

const colunmOrder = [
  "money",
  "steel",
  "titanium",
  "plant",
  "power",
  "heat",
] as const;

export const Component: React.FC<Props> = ({
  game,
  name,
  isCurrentTurn,
  hasPassedForGen,
  resources,
  production,
  events,
  terraformRating,
  turnOrder,
  playerColor,
  numberOfCardsInHand,
  cardPoints,
  totalTradeFleets,
  userId,
}) => {
  const theme = useTheme();

  const Cell: React.FC<{
    children: ReactNode;
    colSpan?: number;
    textAlign?: string;
  }> = ({ children, colSpan, textAlign = "center" }) => {
    return (
      <Box
        component="td"
        sx={{
          textAlign,
          padding: theme.spacing(0.5),
          border: `1px solid ${theme.palette.divider}`,
          "& img": {
            width: "24px",
            height: "auto",
          },
        }}
        colSpan={colSpan}
      >
        {children}
      </Box>
    );
  };

  const numUnusedTradeFleets = () => {
    const usedTradeFleets = game.colonyTiles.filter(
      (coloyTile) => coloyTile.currentTradeFleetPlayerId === userId
    ).length;
    return totalTradeFleets - usedTradeFleets;
  };

  return (
    <StyledCard>
      <CardContent>
        <Typography
          variant="h5"
          sx={{
            backgroundColor: playerColorToHex(playerColor),
            color: theme.palette.getContrastText(playerColorToHex(playerColor)),
            padding: theme.spacing(1),
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <span>{name}</span>
            <span>
              {Array.from({ length: numUnusedTradeFleets() }).map(
                (_, index) => "🚀"
              )}
            </span>
          </Box>
        </Typography>
        <Box>
          <Box component="table" sx={{ borderSpacing: "0", width: "100%" }}>
            <Box component="tbody">
              <Box component="tr">
                <Box
                  component="td"
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    padding: 0,
                  }}
                >
                  <TurnIndicator
                    turnState={{
                      hasPassedForGen,
                      isCurrentTurn,
                    }}
                  >
                    {hasPassedForGen
                      ? "Passed"
                      : isCurrentTurn
                      ? "Their Turn"
                      : ""}
                  </TurnIndicator>
                </Box>
                {colunmOrder.map((resourceKey) => (
                  <Cell key={resourceKey}>
                    <img
                      src={resourceTypeToResourceImage(resourceKey)}
                      alt={resourceKey}
                      style={{ width: "18px" }}
                    />
                  </Cell>
                ))}
              </Box>
              <Box component="tr">
                <Cell>Resources</Cell>
                {colunmOrder.map((resourceKey) => (
                  <Cell key={resourceKey}>{resources[resourceKey]}</Cell>
                ))}
              </Box>
              <Box component="tr">
                <Cell>Production</Cell>
                {colunmOrder.map((resourceKey) => (
                  <Cell key={resourceKey}>
                    {production[resourceKey] > 0 ? "+" : ""}
                    {production[resourceKey]}
                  </Cell>
                ))}
              </Box>
              <Box component="tr">
                <Cell colSpan={3} textAlign="left">{`Events: ${events}`}</Cell>
                <Cell
                  colSpan={4}
                  textAlign="left"
                >{`TR: ${terraformRating}`}</Cell>
              </Box>
              <Box component="tr"></Box>
              <Box component="tr">
                <Cell
                  colSpan={3}
                  textAlign="left"
                >{`Turn this gen: ${turnOrder}`}</Cell>

                <Cell colSpan={4} textAlign="left">
                  <Tooltip
                    title={`Current card points: ${cardPoints}`}
                    placement="top"
                  >
                    <Box>{`Cards: ${numberOfCardsInHand}`}</Box>
                  </Tooltip>
                </Cell>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

Component.displayName = "PlayerSummary";
