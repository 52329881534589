import React from "react";
import { Card } from "@mui/material";
import { tileNameToRequirement } from "utils/action_helpers";
export interface Props {
  tileName: string;
}

export const Component: React.FC<Props> = ({ tileName }) => {
  const requirement = tileNameToRequirement(tileName);
  return (
    <Card
      sx={{
        borderRadius: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2>Place Tile</h2>
      {requirement !== "" ? <h4>Requirement: {requirement}</h4> : null}
    </Card>
  );
};

Component.displayName = "PlaceTile";
