import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { createLobby } from "api/game-lobby/create-lobby";
import { useNavigate } from "react-router-dom";

export interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const Component: React.FC<Props> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleCreateLobby = () => {
    callCreateLobbyApi().then((data) => {
      if (data) {
        navigate(`/lobby/${data._id}`);
      } else {
        console.error(`Error creating lobby`);
      }
      onClose();
    });
  };

  const callCreateLobbyApi = () => {
    if (password === "") {
      return createLobby({ name });
    } else {
      return createLobby({ name, password });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Create New Game Lobby</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Lobby Name"
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Lobby Password (Optional)"
          type="password"
          fullWidth
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreateLobby} color="primary" variant="contained">
          Create Lobby
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Component.displayName = "GameLobbyManager";
