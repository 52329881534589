import React, { useState } from "react";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import * as RegistrationForm from "components/pages/registration/RegistrationForm";

export interface Props {}

export const Component: React.FC<Props> = () => {
  const [registrationEmailSent, setRegistrationEmailSent] =
    useState<boolean>(false);
  const renderLoginRedirectCard = () => {
    return (
      <Container maxWidth="sm">
        <Card sx={{ m: "auto", mt: 2 }}>
          <CardContent
            sx={{
              padding: "16px",
              "&:last-child": { paddingBottom: "16px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body1"
              component="p"
              sx={{ lineHeight: "normal" }}
            >
              Already have an account?{" "}
              <Link
                to="/login"
                style={{
                  marginLeft: "5px",
                  textDecoration: "none",
                }}
              >
                Login here
              </Link>
            </Typography>
          </CardContent>
        </Card>
      </Container>
    );
  };

  const renderConfirmationCard = () => {
    return (
      <Container maxWidth="sm">
        <Card sx={{ m: "auto", mt: 2 }}>
          <CardContent
            sx={{
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" sx={{ paddingBottom: "8px" }}>
              Register
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{ lineHeight: "normal" }}
            >
              Your registration is now pending. Check your email to activate
              your account.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    );
  };

  return (
    <Box>
      {registrationEmailSent ? (
        renderConfirmationCard()
      ) : (
        <RegistrationForm.Component
          onComplete={() => setRegistrationEmailSent(true)}
        />
      )}
      {renderLoginRedirectCard()}
    </Box>
  );
};

Component.displayName = "RegistrationPage";
