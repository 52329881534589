import React, { useState } from "react";
import { MilestoneOrAwardType } from "api/game/GameType";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
} from "@mui/material";
import * as FundActionModal from "components/pages/game/decision-modals/FundActionModal";

export interface Props {
  milestoneOrAwards: MilestoneOrAwardType[];
  type: "milestone" | "award";
  isOpen: boolean;
  onClose: () => void;
}

export const Component: React.FC<Props> = ({
  milestoneOrAwards,
  type,
  isOpen,
  onClose,
}) => {
  const [selectedMilestoneOrAwardName, setSelectedMilestoneOrAwardName] =
    useState<string>("");

  const renderMilestoneOrAwardSelectionModal = () => {
    return (
      <Box>
        <Dialog
          open={isOpen}
          onClose={onClose}
          aria-labelledby="confirm-decision-title"
        >
          <DialogTitle id="confirm-decision-title">
            Confirm Decision
          </DialogTitle>
          {type === "milestone" ? "Claim a Milestone" : "Claim an Award"}
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {milestoneOrAwards.map(renderButton)}
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const renderButton = (milestoneOrAward: MilestoneOrAwardType) => {
    return (
      <Button
        key={milestoneOrAward.name}
        variant="contained"
        onClick={() => setSelectedMilestoneOrAwardName(milestoneOrAward.name)}
        sx={{ m: 1, width: "250px" }}
      >
        {milestoneOrAward.plain_name}
      </Button>
    );
  };

  const renderPaymentSelectionModal = () => {
    const ActionData =
      type === "milestone"
        ? { milestone: selectedMilestoneOrAwardName }
        : { award: selectedMilestoneOrAwardName };

    return (
      <FundActionModal.Component
        isOpen={isOpen}
        onClose={() => {
          setSelectedMilestoneOrAwardName("");
          onClose();
        }}
        description={selectedMilestoneOrAwardName}
        // description="This is a test"
        ActionId={type === "milestone" ? "CLAIM_MILESTONE" : "FUND_AWARD"}
        DecisionId="MAIN_ACTION"
        ActionData={ActionData}
      />
    );
  };

  const renderComponent = () => {
    if (selectedMilestoneOrAwardName === "") {
      return renderMilestoneOrAwardSelectionModal();
    }
    return renderPaymentSelectionModal();
  };

  return renderComponent();
};

Component.displayName = "SelectMilestoneOrAwardModal";
