import { updateGame } from "api/game/update-game";
import { getGame } from "api/game/get-game";
import { NotifierFunctionType } from "api/common/notifier";

export const executeGameAction = async ({
  gameId,
  ActionData,
  ActionId,
  DecisionId,
  setGame,
  enqueueSnackbar,
}: {
  gameId: string;
  ActionData?: any;
  ActionId: string;
  DecisionId: string;
  setGame: (game: any) => void;
  enqueueSnackbar: NotifierFunctionType;
}): Promise<void> => {
  try {
    await updateGame(gameId, {
      ActionData,
      ActionId,
      DecisionId,
    });
    const updatedGame = await getGame(gameId);
    if (updatedGame === null) {
      console.error("Game is null");
      enqueueSnackbar("Failed to update game.", { variant: "error" });
    }
    setGame(updatedGame);
  } catch (error: any) {
    console.error("Error updating game:", error);
    if (error.response?.data?.message) {
      enqueueSnackbar(error.response.data.message, { variant: "error" });
    } else {
      enqueueSnackbar("An error occurred", { variant: "error" });
    }
  }
};
