import axios from "axios";
import { BASE_URL } from "utils/constants";
import { getAuthToken } from "utils/auth_helpers";

export const resetGame: (gameId: string) => Promise<void> = (gameId) => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${getAuthToken()}`;

  return axios
    .post(`/api/game/${gameId}/reset`, [])
    .then(() => {})
    .catch((err) => {
      console.log("Failure");
      console.log(err);
    });
};
