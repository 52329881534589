import React, { useState } from "react";
import * as GameCard from "components/pages/game/GameCard";
import { CardType, SelfPlayerType } from "api/game/GameType";
import { Box, Typography } from "@mui/material";
import * as PlayCardModal from "components/pages/game/decision-modals/PlayCardModal";

export interface Props {
  player: SelfPlayerType;
  isClickable: boolean;
}

export const Component: React.FC<Props> = ({ player, isClickable }) => {
  const cards = player.hand.filter((card) => !card.isCorporation);
  const [selectedCard, setSelectedCard] = useState<CardType | null>(null);

  return (
    <Box>
      <Typography
        variant="h5"
        gutterBottom
        color="textPrimary"
        style={{ color: "#fff" }}
      >
        Your Hand
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        {cards.length === 0 ? (
          <Typography sx={{ color: "white", fontStyle: "italic" }}>
            You have no cards in your hand
          </Typography>
        ) : null}
        {cards.map((card) => (
          <GameCard.Component
            key={card.name}
            name={card.name}
            isCorporation={card.isCorporation}
            isPrelude={card.cardType === "PRELUDE"}
            tooltip={card.tooltip}
            onClick={() => {
              if (isClickable) {
                setSelectedCard(card);
              }
            }}
          />
        ))}
      </Box>
      {selectedCard !== null ? (
        <PlayCardModal.Component
          card={selectedCard}
          cardTooltip={selectedCard.tooltip}
          isOpen={selectedCard !== null}
          onClose={() => {
            setSelectedCard(null);
          }}
        />
      ) : null}
    </Box>
  );
};

Component.displayName = "PlayerHand";
