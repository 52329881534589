import axios from "axios";
import { BASE_URL, Map } from "utils/constants";
import { getAuthToken } from "utils/auth_helpers";

export type GameLobbyType = {
  creator: {
    UserId: string;
    name: string;
  };
  _id: string;
  name: string;
  mapName: Map;
  preludeActive: boolean;
  venusActive: boolean;
  solarActive: boolean;
  coloniesActive: boolean;
  players: PlayerType[];
  isPasswordProtected: boolean;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
};

export type PlayerType = {
  _id: string;
  UserId: string;
  name: string;
};

export const getGameLobbies: () => Promise<GameLobbyType[]> = () => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.get["Content-Type"] = "application/json";
  axios.defaults.headers.get["Authorization"] = `Bearer ${getAuthToken()}`;

  return axios
    .get("/api/game-lobby")
    .then((res) => {
      return res.data as GameLobbyType[];
    })
    .catch((err) => {
      console.log("Failure");
      console.log(err);
      return [];
    });
};

export const getGameLobby: (id: string) => Promise<GameLobbyType> = (id) => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.get["Content-Type"] = "application/json";
  axios.defaults.headers.get["Authorization"] = `Bearer ${getAuthToken()}`;

  return axios
    .get(`/api/game-lobby/${id}`)
    .then((res) => {
      return res.data as GameLobbyType;
    })
    .catch((err) => {
      console.log("Failure");
      console.log(err);
      return {} as GameLobbyType;
    });
};
