import React, { useContext } from "react";
import { Box, Card } from "@mui/material";
import { ChooseCardDecisionType } from "api/game/GameType";
import { useParams } from "react-router-dom";
import { GameContext } from "components/pages/game/GamePage";
import * as GameCard from "components/pages/game/GameCard";
import * as TfmButton from "components/core/TfmButton";
import { executeGameAction } from "api/game/gameActions";
import { useSnackbar } from "notistack";

export interface Props {
  options: ChooseCardDecisionType[];
  canChooseNone?: boolean;
}

export const Component: React.FC<Props> = ({ options, canChooseNone }) => {
  const { gameId } = useParams();
  const { setGame } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();

  const onClick = (target: string | number) => {
    if (gameId === undefined) {
      console.error("Game ID is undefined");
      return;
    }

    executeGameAction({
      gameId,
      ActionData: {
        target,
      },
      ActionId: "SELECT_TARGET",
      DecisionId: "TARGET_CARD",
      setGame,
      enqueueSnackbar,
    });
  };

  return (
    <Card
      sx={{
        borderRadius: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2>Choose where to add a resource</h2>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "10px",
        }}
      >
        {options.map((option) => (
          <Box
            sx={{
              padding: "10px",
            }}
          >
            <GameCard.Component
              key={option.name}
              name={option.name}
              isCorporation={option.isCorporation}
              tooltip={option.tooltip}
              counters={option.counters}
              counterType={option.counterType}
              onClick={() => onClick(option.name)}
            />
          </Box>
        ))}
        {canChooseNone ? (
          <TfmButton.Component
            text={"No Target"}
            styles={{ width: "110px" }}
            onClick={() => onClick(-1)}
          />
        ) : null}
      </Box>
    </Card>
  );
};

Component.displayName = "ChooseTargetCard";
