import * as Navigation from "./Navigation";
import { createContext, useState, useEffect } from "react";
import { getAuthToken } from "utils/auth_helpers";
import jwt_decode from "jwt-decode";
import backgroundImage from "./assets/mars.jpeg";
import { Outlet } from "react-router-dom";
import { SnackbarProvider } from "notistack";

export type CurrentUser = {
  id: string;
  _id: string;
  username: string;
  email: string;
  verified: boolean;
};

export const CurrentUserContext = createContext<{
  currentUser: CurrentUser | null;
  setCurrentUser: (u: CurrentUser | null) => void;
}>({
  currentUser: null,
  setCurrentUser: () => {},
});

function App() {
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);

  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      const decodedUser: CurrentUser = jwt_decode(token);
      if (decodedUser) {
        setCurrentUser(decodedUser);
      }
    }
  }, []);

  return (
    <div
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${backgroundImage})`,
        height: "100vh",
        overflow: "auto",
        backgroundSize: "cover",
      }}
    >
      <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
        <SnackbarProvider maxSnack={3}>
          <Navigation.Component />
          <div id="detail" style={{ flexGrow: 1 }}>
            <Outlet />
          </div>
        </SnackbarProvider>
      </CurrentUserContext.Provider>
    </div>
  );
}

export default App;
