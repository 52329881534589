import React, { ReactNode } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FinalScoreType } from "api/game/GameType";

export interface Props {
  finalScores: FinalScoreType[];
}
const PointCategories = [
  "Terraform Rating",
  "Milestones",
  "Awards",
  "Cities & Greeneries",
  "Cards",
  "Final Score",
];

export const Component: React.FC<Props> = ({ finalScores }) => {
  const theme = useTheme();
  const renderResultsHeaders = () => {
    return (
      <Box component="tr">
        <Cell isBold={true}>Player</Cell>
        {PointCategories.map((category) => (
          <Cell isBold={true}>{category}</Cell>
        ))}
      </Box>
    );
  };

  const renderPlayerResultsRow = (finalScore: FinalScoreType) => {
    return (
      <Box component="tr">
        <Cell>{finalScore.player.name}</Cell>
        <Cell>{finalScore.terraformRating}</Cell>
        <Cell>{finalScore.milestones}</Cell>
        <Cell>{finalScore.awards}</Cell>
        <Cell>{finalScore.tilePoints}</Cell>
        <Cell>{finalScore.cardPoints}</Cell>
        <Cell>{finalScore.finalScore}</Cell>
      </Box>
    );
  };

  const renderFinalScoreTable = () => {
    return (
      <Box component="table" sx={{ borderSpacing: "0" }}>
        <Box component="tbody">
          <Box component="tr">
            {renderResultsHeaders()}
            {finalScores.map((finalScore) =>
              renderPlayerResultsRow(finalScore)
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderDivider = () => {
    return (
      <Box sx={{ backgroundColor: "#999999", textAlign: "center" }}>
        <Typography variant="h4" sx={{ mt: 4 }}>
          End of Game Summary
        </Typography>
      </Box>
    );
  };

  const Cell: React.FC<{
    children: ReactNode;
    colSpan?: number;
    isBold?: boolean;
  }> = ({ children, colSpan, isBold = false }) => {
    return (
      <Box
        component="td"
        sx={{
          textAlign: "center",
          padding: theme.spacing(1.25),
          border: `1px solid ${theme.palette.divider}`,
          fontWeight: isBold ? "bold" : "normal",
        }}
        colSpan={colSpan}
      >
        {children}
      </Box>
    );
  };

  return (
    <Box>
      {renderDivider()}
      <Box>
        <Card>
          <CardContent
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {renderFinalScoreTable()}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

Component.displayName = "FinishedGameSummary";
