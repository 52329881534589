import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Slider,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { GameContext } from "components/pages/game/GamePage";
import { canUseHeat, hasFundingOptions } from "utils/funding_helpers";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { executeGameAction } from "api/game/gameActions";
import { CardType, TapOptionType } from "api/game/GameType";

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  tapOption: TapOptionType;
  DecisionId: string;
  description?: string;
  card?: CardType;
  content?: React.ReactNode;
  costsMoney?: boolean;
}

export const Component: React.FC<Props> = ({
  isOpen,
  onClose,
  tapOption,
  DecisionId,
  description,
  card,
  content,
  costsMoney = true,
}) => {
  const { game } = useContext(GameContext);
  const { player } = game;
  const { gameId } = useParams();
  const { setGame } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();
  const [titanium, setTitanium] = useState<number>(0);
  const [steel, setSteel] = useState<number>(0);
  const [heat, setHeat] = useState<number>(0);

  const onConfirm = () => {
    if (gameId === undefined) {
      console.error("Game ID is undefined");
      return;
    }

    let additionalActionData: {
      heat?: number;
      titanium?: number;
      steel?: number;
      card?: string;
    } = {};
    if (heat > 0) {
      additionalActionData.heat = heat;
    }
    if (titanium > 0) {
      additionalActionData.titanium = titanium;
    }
    if (steel > 0) {
      additionalActionData.steel = steel;
    }
    if (card) {
      additionalActionData.card = card.name;
    }

    executeGameAction({
      gameId,
      ActionData: {
        tapOption: tapOption.tapOption,
        ...additionalActionData,
      },
      ActionId: "TAP_CARD",
      DecisionId,
      setGame,
      enqueueSnackbar,
    }).then(() => {
      onClose();
    });
  };

  const renderSlider = ({
    name,
    currentAmount,
    max,
    setValue,
  }: {
    name: string;
    currentAmount: number;
    max: number;
    setValue: (x: number) => void;
  }) => {
    return (
      <Box
        sx={{ mt: 2, width: "100%", padding: "24px", boxSizing: "border-box" }}
      >
        <Typography>
          {name} (<em>{max} total</em>)
        </Typography>
        <TextField
          fullWidth
          type="number"
          value={currentAmount}
          onChange={(e) => {
            setValue(Number(e.target.value));
          }}
          InputProps={{
            inputProps: { min: 0, max: max },
          }}
          margin="dense"
        />
        <Slider
          value={currentAmount}
          onChange={(e, newValue) => setValue(newValue as number)}
          aria-labelledby="input-slider"
          min={0}
          max={max}
          valueLabelDisplay="auto"
        />
      </Box>
    );
  };

  const renderSliders = () => {
    return (
      <>
        {canUseHeat(player)
          ? renderSlider({
              name: "Heat",
              currentAmount: heat,
              max: player.resources.heat,
              setValue: setHeat,
            })
          : null}
        {tapOption.canUseTitanium
          ? renderSlider({
              name: "Titanium",
              currentAmount: titanium,
              max: player.resources.titanium,
              setValue: setTitanium,
            })
          : null}
        {tapOption.canUseSteel
          ? renderSlider({
              name: "Steel",
              currentAmount: steel,
              max: player.resources.steel,
              setValue: setSteel,
            })
          : null}
      </>
    );
  };

  const renderFundingSelectionModal = () => {
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="fund-action-modal"
      >
        <DialogTitle id="fund-aciton-modal">Choose Funding Method</DialogTitle>
        <DialogActions sx={{ flexDirection: "column", display: "flex" }}>
          <Typography>
            Select how you would like to pay for <strong>{description}</strong>
          </Typography>
          {renderSliders()}
          <Typography>
            <em>Any remaining balance will be paid for in money.</em>
          </Typography>
          <Box>
            <Button onClick={onClose} color="secondary">
              Decline
            </Button>
            <Button onClick={() => onConfirm()} color="primary">
              Confirm
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

  const renderConfirmationModal = () => {
    const finalContent = content ?? (
      <Typography>
        Confirm you want to play <strong>{description}</strong>
      </Typography>
    );
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="confirm-decision-title"
      >
        <DialogTitle id="confirm-decision-title">Confirm Decision</DialogTitle>
        <DialogContent>{finalContent}</DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Decline
          </Button>
          <Button onClick={() => onConfirm()} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return costsMoney && hasFundingOptions({ player, card })
    ? renderFundingSelectionModal()
    : renderConfirmationModal();
};

Component.displayName = "FundActionModal";
