import axios from "axios";
import { BASE_URL } from "utils/constants";
import { NotifierFunctionType } from "api/common/notifier";

function registerUser({
  username,
  email,
  password,
  enqueueSnackbar,
}: {
  username: string;
  email: string;
  password: string;
  enqueueSnackbar: NotifierFunctionType;
}) {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";

  return axios
    .post("/api/user/register", {
      username,
      email,
      password,
    })
    .catch((error) => {
      if (error.response?.data?.message) {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("An error occurred", { variant: "error" });
      }
    });
}

export default registerUser;
