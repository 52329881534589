import axios from "axios";
import { BASE_URL } from "utils/constants";
import { NotifierFunctionType } from "api/common/notifier";

export const resetPassword = ({
  password,
  key,
  enqueueSnackbar,
}: {
  password: string;
  key: string;
  enqueueSnackbar: NotifierFunctionType;
}): Promise<void> => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";

  return axios
    .post("/api/user/reset", {
      key,
      password,
    })
    .then(() => {})
    .catch((error) => {
      if (error.response?.data?.message) {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("An error occurred", { variant: "error" });
      }
    });
};
