import { CardType } from "api/game/GameType";
import { STORAGE_BASE_URL } from "utils/constants";

export const isEventCard = (card: CardType) => {
  return card.tags.some((tag: string) => tag.toLocaleLowerCase() === "event");
};

export const getCardImageUrl = (name: string) => {
  const formattedName = name
    .toLowerCase()
    .replace(/[!"#$%&'()*+,./:;<=>?@[\]^_`{|}~]/g, "")
    .replace(/\s+/g, "_");
  const result = `${STORAGE_BASE_URL}/cards/${formattedName}.jpg`;
  return result;
};
