import { LOCALSTORAGE_TOKEN_KEY } from "utils/constants";

export const isLoggedIn = () => {
  return !!getAuthToken();
};

export const getAuthToken: () => string | null = () => {
  return localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
};

export const clearAuthToken: () => void = () => {
  localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
};

export const setAuthToken: (x: string) => void = (token: string) => {
  localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token);
};
