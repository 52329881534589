import React, { useState } from "react";
import { Box, Card } from "@mui/material";
import { CardType } from "api/game/GameType";
import * as GameCard from "components/pages/game/GameCard";
import * as TapCardModal from "components/pages/game/decision-modals/TapCardModal";

export interface Props {
  cards: CardType[];
}

export const Component: React.FC<Props> = ({ cards }) => {
  const [tappedCard, setTappedCard] = useState<CardType | null>(null);

  return (
    <Card
      sx={{
        borderRadius: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2>Choose Card to Tap</h2>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "10px",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {cards.map((card) => (
          <Box
            sx={{
              padding: "10px",
            }}
          >
            <GameCard.Component
              key={card.name}
              name={card.name}
              isCorporation={card.isCorporation}
              tooltip={card.tooltip}
              onClick={() => setTappedCard(card)}
            />
          </Box>
        ))}
      </Box>
      {tappedCard !== null ? (
        <TapCardModal.Component
          card={tappedCard}
          isOpen={tappedCard !== null}
          onClose={() => setTappedCard(null)}
          DecisionId={"VIRON_TAP"}
        />
      ) : null}
    </Card>
  );
};

Component.displayName = "TapAnotherCard";
