import React, { useState, useContext, ReactChild } from "react";
import { Box, Typography } from "@mui/material";
import { GameType } from "api/game/GameType";
import Card from "@mui/material/Card";
import * as PassForGenModal from "components/pages/game/decision-modals/PassForGenModal";
import * as RaiseTempWithHeatModal from "components/pages/game/decision-modals/RaiseTempWithHeatModal";
import * as PlantGreeneryWithPlantsModal from "components/pages/game/decision-modals/PlantGreeneryWithPlantsModal";
import * as SelectMilestoneOrAwardModal from "components/pages/game/decision-modals/SelectMilestoneOrAwardModal";
import * as TfmButton from "components/core/TfmButton";
import { isMultiplayerGame } from "utils/user_helpers";
import { GameContext } from "components/pages/game/GamePage";
import { executeGameAction } from "api/game/gameActions";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import * as FundActionModal from "components/pages/game/decision-modals/FundActionModal";
import * as BuildColonyModal from "components/pages/game/decision-modals/BuildColonyModal";
import * as TradeWithColonyModal from "components/pages/game/decision-modals/TradeWithColonyModal";
import * as ForfeitModal from "components/pages/game/decision-modals/ForfeitComponent";

interface Props {
  game: GameType;
}

export const Component: React.FC<Props> = ({ game }) => {
  const [openActionModalName, setOpenActionModalName] = useState<string>("");
  const { gameId } = useParams();
  const { setGame } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();

  const sectionContainer = (title: string, children: ReactChild) => {
    return (
      <Box flexGrow={1} display="block" mt={4} className="action-buttons">
        <Box display="flex">
          <Box flexGrow={1}>
            <Box mx={1}>
              <Typography variant="h5">{title}</Typography>
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const onAction = ({
    ActionData,
    ActionId,
    DecisionId,
  }: {
    ActionData: any;
    ActionId: string;
    DecisionId: string;
  }) => {
    if (gameId === undefined) {
      console.error("Game ID is undefined");
      return;
    }

    executeGameAction({
      gameId,
      ActionData,
      ActionId,
      DecisionId,
      setGame,
      enqueueSnackbar,
    });
  };

  const renderButtons = () => {
    return (
      <>
        <Box sx={{ maxWidth: "300px", display: "flex", flexDirection: "row" }}>
          {renderStandardProjectSection()}
        </Box>
        <Box sx={{ maxWidth: "300px", display: "flex", flexDirection: "row" }}>
          {renderOtherActionsSection()}
        </Box>
      </>
    );
  };

  const renderStandardProjectSection = () => {
    const buttons = (
      <>
        <TfmButton.Component
          text="Sell Card(s)"
          onClick={() =>
            onAction({
              ActionData: {},
              ActionId: "STANDARD_SELL",
              DecisionId: "MAIN_ACTION",
            })
          }
        />
        <TfmButton.Component
          text="Power Plant - 11"
          onClick={() => setOpenActionModalName("STANDARD_POWER")}
        />
        <TfmButton.Component
          text="Asteroid (Temp) - 14"
          onClick={() => setOpenActionModalName("STANDARD_TEMP")}
        />
        <TfmButton.Component
          text="Aquifer (Ocean) - 18"
          onClick={() => setOpenActionModalName("STANDARD_OCEAN")}
        />
        <TfmButton.Component
          text="Greenery - 23"
          onClick={() => setOpenActionModalName("STANDARD_GREENERY")}
        />
        <TfmButton.Component
          text="City - 25"
          onClick={() => setOpenActionModalName("STANDARD_CITY")}
        />
        {game.venusActive ? (
          <TfmButton.Component
            text="Air Scraping (Venus) - 15"
            onClick={() => setOpenActionModalName("STANDARD_VENUS")}
          />
        ) : null}
        {game.coloniesActive ? (
          <TfmButton.Component
            text="Build Colony - 17"
            onClick={() => setOpenActionModalName("STANDARD_BUILD_COLONY")}
          />
        ) : null}
      </>
    );

    return sectionContainer("Standard Projects", buttons);
  };

  const renderOtherActionsSection = () => {
    const buttons = (
      <>
        {game.player.resources.plant >= game.player.plantsPerGreenery ? (
          <TfmButton.Component
            text={`Greenery with ${game.player.plantsPerGreenery} plants`}
            onClick={() => setOpenActionModalName("PLANT_GREENERY")}
            styles={{
              borderColor: "#28a745",
              backgroundColor: "#28a745",
              color: "#fff",
              ":hover": {
                backgroundColor: "#218838",
              },
            }}
          />
        ) : null}
        {game.player.resources.heat >= 8 && game.temperatureSteps < 19 ? (
          <TfmButton.Component
            text="Temp with 8 heat"
            onClick={() => setOpenActionModalName("HEAT_TEMP")}
            styles={{
              borderColor: "#dc3545",
              backgroundColor: "#dc3545",
              color: "#fff",
              ":hover": {
                backgroundColor: "#9a0007",
              },
            }}
          />
        ) : null}
        {game.coloniesActive ? (
          <TfmButton.Component
            text="Trade with Colony"
            onClick={() => setOpenActionModalName("TRADE_COLONY")}
          />
        ) : null}
        {isMultiplayerGame(game) ? (
          <TfmButton.Component
            text="Claim Milestone"
            onClick={() => setOpenActionModalName("SELECT_MILESTONE")}
          />
        ) : null}
        {isMultiplayerGame(game) ? (
          <TfmButton.Component
            text="Fund an award"
            onClick={() => setOpenActionModalName("SELECT_AWARD")}
          />
        ) : null}
        <TfmButton.Component
          text={
            game.player.actionsTaken === 0
              ? "Pass for the Generation"
              : "Pass 2nd Action"
          }
          onClick={() => {
            if (game.player.actionsTaken === 0) {
              setOpenActionModalName("PASS");
            } else {
              onAction({
                ActionData: {
                  autoPass: false,
                },
                ActionId: "PASS",
                DecisionId: "MAIN_ACTION",
              });
            }
          }}
        />
        {game.allPlayers.length <= 2 ? (
          <TfmButton.Component
            text="Forfeit"
            onClick={() => setOpenActionModalName("FORFEIT")}
          />
        ) : null}
      </>
    );
    return sectionContainer("Other Actions", buttons);
  };

  const placeModalComponents = () => {
    return (
      <>
        <FundActionModal.Component
          isOpen={[
            "STANDARD_POWER",
            "STANDARD_TEMP",
            "STANDARD_OCEAN",
            "STANDARD_GREENERY",
            "STANDARD_CITY",
            "STANDARD_VENUS",
          ].includes(openActionModalName)}
          onClose={() => setOpenActionModalName("")}
          ActionId={openActionModalName}
          DecisionId="MAIN_ACTION"
        />
        <BuildColonyModal.Component
          isOpen={openActionModalName === "STANDARD_BUILD_COLONY"}
          onClose={() => setOpenActionModalName("")}
        />
        <TradeWithColonyModal.Component
          isOpen={openActionModalName === "TRADE_COLONY"}
          onClose={() => setOpenActionModalName("")}
        />
        <RaiseTempWithHeatModal.Component
          isOpen={openActionModalName === "HEAT_TEMP"}
          onClose={() => setOpenActionModalName("")}
        />
        <PlantGreeneryWithPlantsModal.Component
          isOpen={openActionModalName === "PLANT_GREENERY"}
          onClose={() => setOpenActionModalName("")}
          plantsPerGreenery={game.player.plantsPerGreenery}
        />
        <SelectMilestoneOrAwardModal.Component
          milestoneOrAwards={game.milestones}
          type="milestone"
          isOpen={openActionModalName === "SELECT_MILESTONE"}
          onClose={() => setOpenActionModalName("")}
        />
        <SelectMilestoneOrAwardModal.Component
          milestoneOrAwards={game.awards}
          type="award"
          isOpen={openActionModalName === "SELECT_AWARD"}
          onClose={() => setOpenActionModalName("")}
        />
        <PassForGenModal.Component
          isOpen={openActionModalName === "PASS"}
          onClose={() => setOpenActionModalName("")}
        />
        <ForfeitModal.Component
          isOpen={openActionModalName === "FORFEIT"}
          onClose={() => setOpenActionModalName("")}
        />
      </>
    );
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        borderRadius: 0,
      }}
    >
      {renderButtons()}
      {placeModalComponents()}
    </Card>
  );
};

Component.displayName = "MainActions";
