import React from "react";
import * as DecisionBaseModal from "components/pages/game/decision-modals/DecisionBaseModal";
import { Typography } from "@mui/material";

export interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const Component: React.FC<Props> = ({ isOpen, onClose }) => {
  const renderContent = () => {
    return (
      <div>
        <Typography variant="body1">
          Confirm you want to <strong>FORFEIT THE GAME</strong>.
        </Typography>
      </div>
    );
  };

  return (
    <DecisionBaseModal.Component
      content={renderContent()}
      isOpen={isOpen}
      onClose={onClose}
      ActionData={{
        autoPass: false,
      }}
      ActionId="FORFEIT"
      DecisionId="MAIN_ACTION"
    />
  );
};

Component.displayName = "ForfeitModal";
