import axios from "axios";
import { BASE_URL } from "utils/constants";
import { getAuthToken } from "utils/auth_helpers";

export type UpdateGameType = {
  ActionData: any;
  ActionId: string;
  DecisionId: string;
};

export const updateGame: (
  gameId: string,
  data: UpdateGameType
) => Promise<void> = (gameId, data) => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${getAuthToken()}`;

  return axios.post(`/api/game/${gameId}`, data);
};
