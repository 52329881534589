import login from "api/user/login";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CurrentUserContext } from "../../App";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";

export interface Props {}

export const Component: React.FC<Props> = () => {
  const [email, setEmail] = useState<string>("");
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setCurrentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const updatedEmail = event.currentTarget.value;
    setEmail(updatedEmail);
    if (updatedEmail.length > 0) {
      setEmailErrorMessage("");
    }
  };

  const onPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const updatedPassword = event.currentTarget.value;
    setPassword(updatedPassword);
    if (updatedPassword.length > 0) {
      setPasswordErrorMessage("");
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email.length === 0) {
      setEmailErrorMessage("Please provide an email");
    } else if (password.length === 0) {
      setPasswordErrorMessage("Please provide a password");
    } else {
      setIsLoading(true);
      login({
        email,
        password,
        enqueueSnackbar,
      })
        .then((newCurrentUser) => {
          setCurrentUser(newCurrentUser);
          navigate("/browse");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const renderLoginCard = () => {
    return (
      <Card className="LoginPage" sx={{ maxWidth: 345, m: "auto", mt: 5 }}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Login
          </Typography>
          <form onSubmit={onSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              type="text"
              value={email}
              onChange={(e) => onEmailInputChange(e)}
              fullWidth
              margin="normal"
              error={emailErrorMessage !== ""}
              helperText={emailErrorMessage}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => onPasswordInputChange(e)}
              fullWidth
              margin="normal"
              error={passwordErrorMessage !== ""}
              helperText={passwordErrorMessage}
            />
            <Typography
              variant="body2"
              sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}
            >
              <Link to="/forgot-password" style={{ textDecoration: "none" }}>
                Forgot Password?
              </Link>
            </Typography>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                fullWidth
              >
                Login
              </Button>
            )}
          </form>
        </CardContent>
      </Card>
    );
  };

  const renderRegistrationRedirectCard = () => {
    return (
      <Card
        sx={{
          maxWidth: 345,
          m: "auto",
          mt: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <CardContent
          sx={{
            padding: "16px",
            "&:last-child": { paddingBottom: "16px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{ lineHeight: "normal" }}
          >
            New to TFMars?{" "}
            <Link
              to="/register"
              style={{
                marginLeft: "5px",
                textDecoration: "none",
              }}
            >
              Create an account
            </Link>
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box>
      {renderLoginCard()}
      {renderRegistrationRedirectCard()}
    </Box>
  );
};

Component.displayName = "LoginPage";
