import React, { useState } from "react";
import * as ForgotPasswordForm from "components/pages/forgot-password/ForgotPasswordForm";
import { Card, CardContent, Typography } from "@mui/material";

export interface Props {}

export const Component: React.FC<Props> = () => {
  const [resetPasswordEmailSent, setResetPasswordEmailSent] =
    useState<boolean>(false);

  const renderConfirmationCard = () => {
    return (
      <Card
        className="ForgotPasswordPage"
        sx={{ maxWidth: 345, m: "auto", mt: 5 }}
      >
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Reset Password
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{ lineHeight: "normal" }}
          >
            A link has been sent to your email to reset your password.
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return resetPasswordEmailSent ? (
    renderConfirmationCard()
  ) : (
    <ForgotPasswordForm.Component
      onComplete={() => setResetPasswordEmailSent(true)}
    />
  );
};

Component.displayName = "ForgotPasswordPage";
