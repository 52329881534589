import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as BrowsePage from "components/pages/browse/BrowsePage";
import * as ErrorPage from "components/pages/ErrorPage";
import * as LoginPage from "components/pages/LoginPage";
import * as RegistrationPage from "components/pages/registration/RegistrationPage";
import * as ForgotPasswordPage from "components/pages/ForgotPasswordPage";
import * as CompletedGamesPage from "components/pages/CompletedGamesPage";
import * as AccountVerificationPage from "components/pages/AccountVerificationPage";
import * as PasswordResetPage from "components/pages/PasswordResetPage";
import * as GamePage from "components/pages/game/GamePage";
import * as LobbyPage from "components/pages/lobby/LobbyPage";
import { isLoggedIn } from "utils/auth_helpers";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage.Component />,
    children: [
      {
        index: true,
        loader: async () => {
          if (isLoggedIn()) {
            return redirect("/browse");
          } else {
            return redirect("/login");
          }
        },
      },
      {
        path: "/register",
        element: <RegistrationPage.Component />,
      },
      {
        path: "/login",
        element: <LoginPage.Component />,
      },
      {
        path: "/browse",
        element: <BrowsePage.Component />,
        loader: async () => {
          if (!isLoggedIn()) {
            return redirect("/login");
          }
          return null;
        },
      },
      {
        path: "/forgot-password",
        element: <ForgotPasswordPage.Component />,
        loader: async () => {
          if (isLoggedIn()) {
            return redirect("/browse");
          }
          return null;
        },
      },
      {
        path: "/verify",
        element: <AccountVerificationPage.Component />,
      },
      {
        path: "/password-reset",
        element: <PasswordResetPage.Component />,
      },
      {
        path: "/completed-games",
        element: <CompletedGamesPage.Component />,
        loader: async () => {
          if (!isLoggedIn()) {
            return redirect("/login");
          }
          return null;
        },
      },
      {
        path: "/game/:gameId",
        element: <GamePage.Component />,
      },
      {
        path: "/lobby/:lobbyId",
        element: <LobbyPage.Component />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
