import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import { MilestoneOrAwardType } from "api/game/GameType";
import {
  playerColorToBackgroundHex,
  TRANSPARENT_COLOR,
  DEFAULT_BLACK,
} from "utils/styling_helpers";
import { generateMultilineFromString } from "components/utils/tooltip_helpers";
export interface Props {
  oceans: number;
  oxygen: number;
  temperature: number;
  venus: number;
  generation: number;
  milestones: MilestoneOrAwardType[];
  awards: MilestoneOrAwardType[];
  isSinglePlayer: boolean;
}

export interface StatusLabel {
  label: string;
  className?: string;
  tooltip?: string;
  color?: string;
  isBold?: boolean;
}

export const Component: React.FC<Props> = ({
  oceans,
  oxygen,
  temperature,
  venus,
  generation,
  milestones,
  awards,
  isSinglePlayer,
}) => {
  const theme = useTheme();

  const renderTrStatusBar = ({
    title,
    statusLabels,
    numBars,
    numBarsFilled,
    fillColor,
  }: {
    title: string;
    statusLabels: StatusLabel[];
    numBars: number;
    numBarsFilled: number;
    fillColor: string;
  }) => {
    const widthPercent = 100 / numBars;
    return (
      <Box display="flex" flexDirection="row" alignItems="start" m={1}>
        <Box>
          <Typography
            display="block"
            variant="subtitle1"
            gutterBottom
            width="85px"
            fontSize="10px"
          >
            {title}
          </Typography>
        </Box>
        <Box
          width="545px"
          flexDirection="row"
          display="block"
          border={1}
          borderColor="#9c9c9c"
          className="box-height"
        >
          <Box display="flex">
            {statusLabels.map((statusLabel, index) => (
              <Box
                key={`${statusLabel.label}-fill`}
                width={`${widthPercent}%`}
                height={24}
                bgcolor={index <= numBarsFilled ? fillColor : "transparent"}
              />
            ))}
          </Box>
          <Box display="flex">
            {statusLabels.map((statusLabel) => (
              <Tooltip
                title={statusLabel.tooltip ?? ""}
                placement="top"
                key={`${statusLabel.label}-legend`}
              >
                <Box
                  width={`${widthPercent}%`}
                  textAlign="center"
                  fontSize="10px"
                  color={statusLabel.color}
                  fontWeight={statusLabel.isBold ? "bold" : "normal"}
                >
                  {statusLabel.label}
                </Box>
              </Tooltip>
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderOceanBar = () => {
    const statusLabels: StatusLabel[] = Array.from(
      { length: 10 },
      (_, index) => index
    ).map((index) => ({
      label: index.toString(),
    }));

    return renderTrStatusBar({
      title: "Oceans Played",
      statusLabels,
      numBars: 10,
      numBarsFilled: oceans,
      fillColor: "#6390ff",
    });
  };

  const renderOxygenBar = () => {
    let statusLabels: StatusLabel[] = Array.from(
      { length: 15 },
      (_, index) => index
    ).map((index) => ({
      label: `${index}%`,
    }));
    statusLabels[8].tooltip = "At 8% oxygen, increase the temperature 1 step";
    statusLabels[8].color = "#ff276c";
    statusLabels[8].isBold = true;

    return renderTrStatusBar({
      title: "Oxygen Level",
      statusLabels,
      numBars: 15,
      numBarsFilled: oxygen,
      fillColor: "#79ff79",
    });
  };

  const renderTemperatureBar = () => {
    const statusLabels: StatusLabel[] = Array.from(
      { length: 20 },
      (_, index) => index
    ).map((index) => ({
      label: `${index * 2 - 30}`,
    }));
    statusLabels[3].tooltip =
      "At -24°C, increase your heat production by 1 step";
    statusLabels[5].tooltip =
      "At -20°C, increase your heat production by 1 step";
    statusLabels[3].isBold = true;
    statusLabels[5].isBold = true;
    statusLabels[3].color = "#ffa739";
    statusLabels[5].color = "#ffa739";
    statusLabels[15].tooltip = "At 0°C, place an ocean tile";
    statusLabels[15].isBold = true;
    statusLabels[15].color = "#007bff";

    return renderTrStatusBar({
      title: "Temperature",
      statusLabels,
      numBars: 20,
      numBarsFilled: temperature,
      fillColor: "#ff686b",
    });
  };

  const renderVenusBar = () => {
    const statusLabels: StatusLabel[] = Array.from(
      { length: 16 },
      (_, index) => index
    ).map((index) => ({
      label: (index * 2).toString(),
    }));
    statusLabels[4].tooltip = "At 8% Venus, draw 1 card";
    statusLabels[8].tooltip = "At 16% Venus, gain 1 TR";
    statusLabels[4].isBold = true;
    statusLabels[8].isBold = true;
    statusLabels[4].color = "#6d3eff";
    statusLabels[8].color = "#6d3eff";

    return renderTrStatusBar({
      title: "Venus Scale",
      statusLabels,
      numBars: 16,
      numBarsFilled: venus,
      fillColor: "#a479ff",
    });
  };

  const maybeRenderAwardStatusBar = ({
    title,
    statusLabels,
  }: {
    title: string;
    statusLabels: MilestoneOrAwardType[];
  }) => {
    if (isSinglePlayer) {
      return null;
    }

    const widthPercent = 100 / statusLabels.length;
    return (
      <Box display="flex" flexDirection="row" alignItems="start" m={1}>
        <Box>
          <Typography
            display="block"
            variant="subtitle1"
            gutterBottom
            width="85px"
            fontSize="10px"
          >
            {title}
          </Typography>
        </Box>
        <Box
          width="545px"
          flexDirection="row"
          display="block"
          border={1}
          borderColor="#9c9c9c"
          className="box-height"
        >
          <Box display="flex">
            {statusLabels.map((statusLabel) => (
              <Tooltip
                key={statusLabel.name}
                title={generateMultilineFromString(statusLabel.tooltip)}
                placement="top"
              >
                <Box
                  width={`${widthPercent}%`}
                  height={36}
                  textAlign="center"
                  fontSize="10px"
                  bgcolor={getBackgroundColor(statusLabel)}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    sx={{
                      color: getTextColor(statusLabel),
                      fontSize: "0.6rem",
                      display: "inline",
                    }}
                  >
                    {statusLabel.owner && statusLabel.owner.name ? (
                      <Box style={{ whiteSpace: "pre-line" }}>
                        {`${statusLabel.plain_name}\n(${statusLabel.owner?.name})`}
                      </Box>
                    ) : (
                      statusLabel.plain_name
                    )}
                  </Typography>
                </Box>
              </Tooltip>
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  const getBackgroundColor = (statusLabel: MilestoneOrAwardType) => {
    return statusLabel.owner?.color
      ? playerColorToBackgroundHex(statusLabel.owner.color)
      : TRANSPARENT_COLOR;
  };

  const getTextColor = (statusLabel: MilestoneOrAwardType) => {
    if (!statusLabel.owner?.color) {
      return DEFAULT_BLACK;
    }
    return theme.palette.getContrastText(getBackgroundColor(statusLabel));
  };

  return (
    <Card sx={{ display: "inline-block", borderRadius: 0, width: "650px" }}>
      <Typography variant="h5">
        Game Status - Generation {generation}
      </Typography>
      {renderOceanBar()}
      {renderOxygenBar()}
      {renderTemperatureBar()}
      {renderVenusBar()}
      {maybeRenderAwardStatusBar({
        title: "Milestones",
        statusLabels: milestones,
      })}
      {maybeRenderAwardStatusBar({ title: "Awards", statusLabels: awards })}
    </Card>
  );
};

Component.displayName = "GameStatus";
