import registerUser from "api/user/register";
import React, { useState, FormEvent } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";

export interface Props {
  onComplete: () => void;
}

export const Component: React.FC<Props> = ({ onComplete }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  const [confirmEmailErrorMessage, setConfirmEmailErrorMessage] =
    useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onUsernameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setUsername(event.currentTarget.value);
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.currentTarget.value);
  };

  const onConfirmEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setConfirmEmail(event.currentTarget.value);
  };

  const onPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPassword(event.currentTarget.value);
  };

  const onConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setConfirmPassword(event.currentTarget.value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email !== confirmEmail) {
      setConfirmEmailErrorMessage(
        "It looks like your email addresses are different. Please update them and try again."
      );
    } else if (password !== confirmPassword) {
      setConfirmPasswordErrorMessage(
        "It looks like your passwords are different. Please update them and try again."
      );
    } else {
      setIsLoading(true);
      registerUser({ username, email, password, enqueueSnackbar })
        .then(() => {
          onComplete();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent>
          <Box
            component="form"
            onSubmit={onSubmit}
            noValidate
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Typography variant="h6">Register</Typography>
            <TextField
              label="Username"
              variant="outlined"
              value={username}
              onChange={onUsernameChange}
            />
            <TextField
              label="Email Address"
              variant="outlined"
              value={email}
              onChange={onEmailChange}
            />
            <TextField
              label="Confirm Email Address"
              variant="outlined"
              value={confirmEmail}
              onChange={onConfirmEmailChange}
              error={confirmEmailErrorMessage !== ""}
              helperText={confirmEmailErrorMessage}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={onPasswordChange}
            />
            <TextField
              label="Confirm Password"
              type="password"
              variant="outlined"
              value={confirmPassword}
              onChange={onConfirmPasswordChange}
              error={confirmPasswordErrorMessage !== ""}
              helperText={confirmPasswordErrorMessage}
            />
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Button type="submit" variant="contained">
                Register
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

Component.displayName = "RegistrationForm";
