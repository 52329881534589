import React from "react";
import { Button } from "@mui/material";

interface Props {
  text: string;
  onClick: () => void;
  styles?: any;
}

export const Component: React.FC<Props> = ({ text, onClick, styles }) => {
  return (
    <Button
      onClick={() => onClick()}
      variant="outlined"
      sx={{
        textTransform: "none",
        my: 1,
        width: "100%",
        borderColor: "secondary.main",
        "&:hover": {
          borderColor: "secondary.dark",
        },
        color: "secondary.main",
        ...styles,
      }}
    >
      {text}
    </Button>
  );
};

Component.displayName = "TfmButton";
