import { GameType } from "api/game/GameType";

export const isCurrentPlayersTurn = (game: GameType) => {
  return isSpecificPlayersTurn({ game, userId: game.player.UserId });
};

export const isSpecificPlayersTurn = ({
  game,
  userId,
}: {
  game: GameType;
  userId: string;
}) => {
  return userId === game.decisionStack[0]?.UserId;
};

export const isCurrentPlayerDrafting = (game: GameType) => {
  return (
    game.decisionStack
      .filter((decision) => decision.DecisionId === "DRAFT_CARDS")
      .filter((decision) => decision.UserId === game.player.UserId).length > 0
  );
};

export const isCurrentPlayerSelectingStartingHand = (game: GameType) => {
  return (
    game.decisionStack
      .filter((decision) => decision.DecisionId === "STARTING_HAND")
      .filter((decision) => decision.UserId === game.player.UserId).length > 0
  );
};

export const isSinglePlayerGame = (game: GameType) => {
  return game.allPlayers.length === 1;
};

export const isMultiplayerGame = (game: GameType) => {
  return !isSinglePlayerGame(game);
};
