import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export const Component: React.FC<{}> = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
      }}
    >
      <CircularProgress />
    </div>
  );
};

Component.displayName = "LoadingSpinner";
