import React, { ReactNode } from "react";
import { IconButton, Box } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export interface Props {
  children: ReactNode;
  width?: string | number;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

export const Component: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  className = "",
}) => {
  if (!isOpen) return null;

  return (
    <Box
      className={className}
      sx={{
        width: "450px",
        minWidth: "350px",
        minHeight: "100vh",
        backgroundColor: "white",
        boxShadow: "-2px 0 10px rgba(0,0,0,0.1)",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            right: 0,
            height: "32px",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
            zIndex: 2,
          }}
        >
          <IconButton
            onClick={onClose}
            aria-label="Close sidebar"
            size="small"
            sx={{ padding: 0.5 }}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Box>
  );
};

Component.displayName = "RightSidebar";
