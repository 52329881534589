import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { GameContext } from "components/pages/game/GamePage";
import * as FundActionModal from "components/pages/game/decision-modals/FundActionModal";

export interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const Component: React.FC<Props> = ({ isOpen, onClose }) => {
  const { game } = useContext(GameContext);
  const [selectedColony, setSelectedColony] = useState<string>("");

  const renderButton = ({
    text,
    onClick,
  }: {
    text: string;
    onClick: () => void;
  }) => {
    return (
      <Button
        key={text}
        variant="contained"
        color="primary"
        sx={{ m: 1, width: "250px", alignSelf: "center" }}
        onClick={() => onClick()}
      >
        {text}
      </Button>
    );
  };
  const renderColonySelectionModal = () => {
    return (
      <Dialog
        open={isOpen}
        onClose={() => {
          setSelectedColony("");
          onClose();
        }}
        aria-labelledby="build-colony-modal"
      >
        <DialogTitle id="build-colony-modal">Build A Colony</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Select which Colony Tile you would like to build on.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {game.colonyTiles.map((colonyTile) =>
              renderButton({
                text: colonyTile.name,
                onClick: () => setSelectedColony(colonyTile.name),
              })
            )}
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  const renderComponent = () => {
    if (selectedColony === "") {
      return renderColonySelectionModal();
    }
    return (
      <FundActionModal.Component
        isOpen={isOpen}
        onClose={() => {
          setSelectedColony("");
          onClose();
        }}
        content={
          <Typography variant="body1">
            Confirm you want to build a colony on{" "}
            <strong>{selectedColony}</strong>.
          </Typography>
        }
        ActionId="STANDARD_BUILD_COLONY"
        DecisionId="MAIN_ACTION"
        ActionData={{
          colonyTileName: selectedColony,
        }}
      />
    );
  };

  return renderComponent();
};

Component.displayName = "BuildColonyModal";
