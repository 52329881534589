import axios from "axios";
import { BASE_URL } from "utils/constants";
import { GameType } from "api/game/GameType";
import { getAuthToken } from "utils/auth_helpers";

export const getGame: (gameId: string) => Promise<GameType | null> = (
  gameId
) => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${getAuthToken()}`;

  return axios
    .get(`/api/game/${gameId}`)
    .then((res) => {
      return res.data as GameType;
    })
    .catch((err) => {
      console.log("Failure");
      console.log(err);
      return null;
    });
};

export default getGame;
