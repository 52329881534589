import axios from "axios";
import { Link as RouterLink } from "react-router-dom";
import { isLoggedIn, clearAuthToken } from "utils/auth_helpers";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Component: React.FC<{}> = () => {
  const navigate = useNavigate();

  const renderLoginOrLogoutButton = () => {
    return isLoggedIn() ? renderLogoutButton() : renderLoginButton();
  };

  const renderLoginButton = () => {
    return (
      <Button color="inherit" component={RouterLink} to="/login">
        <Box sx={{ textAlign: "center" }}>Log In</Box>
      </Button>
    );
  };

  const renderLogoutButton = () => {
    const handleLogout = () => {
      clearAuthToken();
      axios.defaults.headers.common["Authorization"] = undefined;
      navigate("/");
    };

    return (
      <Button color="inherit" onClick={handleLogout}>
        <Box sx={{ textAlign: "center" }}>Log Out</Box>
      </Button>
    );
  };

  const maybeRenderCompletedGamesButton = () => {
    if (!isLoggedIn()) {
      return null;
    }

    return (
      <Button color="inherit" component={RouterLink} to="/completed-games">
        <Box sx={{ textAlign: "center" }}>Completed Games</Box>
      </Button>
    );
  };

  const maybeRenderRegisterButton = () => {
    if (isLoggedIn()) {
      return null;
    }

    return (
      <Button color="inherit" component={RouterLink} to="/register">
        <Box sx={{ textAlign: "center" }}>Register</Box>
      </Button>
    );
  };

  return (
    <AppBar position="static" sx={{ maxHeight: "64px" }}>
      <Toolbar>
        <Button
          color="inherit"
          component={RouterLink}
          to="/"
          sx={{ color: "inherit", textTransform: "none" }}
        >
          <Typography variant="h6">TFMars</Typography>
        </Button>

        <Button color="inherit" component={RouterLink} to="/browse">
          Browse
        </Button>
        {maybeRenderCompletedGamesButton()}
        {renderLoginOrLogoutButton()}
        {maybeRenderRegisterButton()}
      </Toolbar>
    </AppBar>
  );
};

Component.displayName = "Navigation";
