import React, { useState, ReactChild } from "react";
import { Button, Box, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import * as SolarOceanModal from "components/pages/game/decision-modals/SolarOceanModal";
import * as SolarTempModal from "components/pages/game/decision-modals/SolarTempModal";
import * as SolarOxygenModal from "components/pages/game/decision-modals/SolarOxygenModal";
import * as SolarVenusModal from "components/pages/game/decision-modals/SolarVenusModal";

interface Props {}

export const Component: React.FC<Props> = () => {
  const [openActionModalName, setOpenActionModalName] = useState<string>("");

  const renderButton = ({
    text,
    onClick,
  }: {
    text: string;
    onClick: () => void;
  }) => {
    return (
      <Button
        onClick={() => onClick()}
        variant="outlined"
        sx={{
          textTransform: "none",
          my: 1,
          width: "100%",
          borderColor: "secondary.main",
          "&:hover": {
            borderColor: "secondary.dark",
          },
          color: "secondary.main",
        }}
      >
        {text}
      </Button>
    );
  };

  const sectionContainer = (title: string, children: ReactChild) => {
    return (
      <Box flexGrow={1} display="block" mt={4} className="action-buttons">
        <Box display="flex">
          <Box flexGrow={1}>
            <Box mx={1}>
              <Typography variant="h5">{title}</Typography>
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderStandardProjectSection = () => {
    const buttons = (
      <>
        {renderButton({
          text: "Ocean",
          onClick: () => setOpenActionModalName("SOLAR_OCEAN"),
        })}
        {renderButton({
          text: "Oxygen",
          onClick: () => setOpenActionModalName("SOLAR_OXYGEN"),
        })}
        {renderButton({
          text: "Temperature",
          onClick: () => setOpenActionModalName("SOLAR_TEMP"),
        })}
        {renderButton({
          text: "Venus",
          onClick: () => setOpenActionModalName("SOLAR_VENUS"),
        })}
      </>
    );

    return sectionContainer("Select Solar Action", buttons);
  };

  const placeModalComponents = () => {
    return (
      <>
        <SolarOceanModal.Component
          isOpen={openActionModalName === "SOLAR_OCEAN"}
          onClose={() => setOpenActionModalName("")}
        />
        <SolarTempModal.Component
          isOpen={openActionModalName === "SOLAR_TEMP"}
          onClose={() => setOpenActionModalName("")}
        />
        <SolarOxygenModal.Component
          isOpen={openActionModalName === "SOLAR_OXYGEN"}
          onClose={() => setOpenActionModalName("")}
        />
        <SolarVenusModal.Component
          isOpen={openActionModalName === "SOLAR_VENUS"}
          onClose={() => setOpenActionModalName("")}
        />
      </>
    );
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        borderRadius: 0,
      }}
    >
      {renderStandardProjectSection()}
      {placeModalComponents()}
    </Card>
  );
};

Component.displayName = "SolarActions";
