import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCompletedGames,
  CompletedGame,
  Player,
} from "api/game/get-completed-games";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import { isLoggedIn } from "utils/auth_helpers";

import { Link as RouterLink } from "react-router-dom";

export const Component: React.FC<{}> = () => {
  const [completedGames, setCompletedGames] = useState<CompletedGame[] | null>(
    null
  );
  const navigate = useNavigate();

  useEffect(() => {
    getCompletedGames().then(setCompletedGames);
  }, []);

  const renderPage = () => {
    return (
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            This is a test
            {completedGames === null ? (
              <CircularProgress />
            ) : (
              renderCompletedGamesList(completedGames)
            )}
          </Grid>
        </Grid>
      </Container>
    );
  };

  const renderCompletedGamesList = (completedGames: CompletedGame[]) => {
    if (completedGames.length === 0) {
      return (
        <Typography variant="h6" gutterBottom color="white">
          You have no completed games
        </Typography>
      );
    }

    return (
      <Box>
        <Typography
          variant="h5"
          gutterBottom
          color="textPrimary"
          style={{ color: "#fff" }}
        >
          Completed Games
        </Typography>
        {completedGames.map(renderCompletedGameTile)}
      </Box>
    );
  };

  const renderCompletedGameTile = (completedGame: CompletedGame) => {
    return (
      <Box margin={2}>
        <Card>
          <RouterLink
            to={`/game/${completedGame._id}`}
            style={{ textDecoration: "none" }}
          >
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {completedGame.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Players:{" "}
                  {completedGame.players
                    .map((player: Player) => player.name)
                    .join(", ")}
                </Typography>
              </CardContent>
            </CardActionArea>
          </RouterLink>
        </Card>
      </Box>
    );
  };

  const redirectToLogin = () => {
    navigate("/login");
    return <div></div>;
  };

  return <>{!isLoggedIn() ? redirectToLogin() : renderPage()}</>;
};

Component.displayName = "CompletedGamesPage";
