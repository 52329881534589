import React, { useState } from "react";
import { Typography, Card, Grid, Box } from "@mui/material";
import { CardType } from "api/game/GameType";
import * as GameCard from "components/pages/game/GameCard";
import * as TapCardModal from "components/pages/game/decision-modals/TapCardModal";

export interface Props {
  cards: CardType[];
  canTap: boolean;
}

export const Component: React.FC<Props> = ({ cards, canTap }) => {
  const [tappedCard, setTappedCard] = useState<CardType | null>(null);

  const renderCorp = () => {
    return cards
      .filter((card) => card.isCorporation)
      .map((card) => (
        <GameCard.Component
          key={card.name}
          name={card.name}
          isCorporation={card.isCorporation}
          isPrelude={card.cardType === "PRELUDE"}
          tooltip={card.tooltip}
          disabled={card.hasBeenTapped}
          hasBeenTapped={card.hasBeenTapped}
          counters={card.counters}
          counterType={card.counterType}
          tapOptions={card.tapOptions}
          onClick={() => {
            if (canTap && card.tapOptions.length > 0 && !card.hasBeenTapped) {
              setTappedCard(card);
            }
          }}
        />
      ));
  };

  const renderCards = (color: "blue" | "green") => {
    return cards
      .filter((card) => card.color === color)
      .filter((card) => !card.isCorporation)
      .map((card) => (
        <GameCard.Component
          key={card.name}
          name={card.name}
          isCorporation={card.isCorporation}
          isPrelude={card.cardType === "PRELUDE"}
          tooltip={card.tooltip}
          disabled={card.hasBeenTapped}
          hasBeenTapped={card.hasBeenTapped}
          counters={card.counters}
          counterType={card.counterType}
          tapOptions={card.tapOptions}
          onClick={() => {
            if (
              canTap &&
              card.tapOptions.length > 0 &&
              card.color === "blue" &&
              !card.hasBeenTapped
            ) {
              setTappedCard(card);
            }
          }}
        />
      ));
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        borderRadius: 0,
      }}
    >
      <Box>
        <Typography variant="h5" sx={{ mt: 4 }}>
          Board
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap" }}>
            {renderCorp()}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap" }}>
            {renderCards("blue")}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap" }}>
            {renderCards("green")}
          </Grid>
        </Grid>
      </Box>
      {tappedCard !== null ? (
        <TapCardModal.Component
          card={tappedCard}
          isOpen={tappedCard !== null}
          onClose={() => setTappedCard(null)}
        />
      ) : null}
    </Card>
  );
};

Component.displayName = "PlayerBoard";
