import React, { useContext } from "react";
import * as GameBoardTile from "components/pages/game/GameBoardTile";
import { GameType, TileType } from "api/game/GameType";
import { GameContext } from "components/pages/game/GamePage";
import { Box } from "@mui/material";
import { executeGameAction } from "api/game/gameActions";
import { useSnackbar } from "notistack";

export interface Props {
  game: GameType;
}

export const Component: React.FC<Props> = ({ game }) => {
  const { setGame } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();

  const tileClicked = (tile: TileType) => {
    if (game.decisionStack.length === 0) {
      console.log("Cannot click tile");
    }

    if (
      game.decisionStack.length > 0 &&
      game.decisionStack[0]?.DecisionId === "PLACE_TILE"
    ) {
      executeGameAction({
        gameId: game._id,
        ActionData: {
          x: tile.x,
          y: tile.y,
        },
        ActionId: "PLACE_TILE",
        DecisionId: "PLACE_TILE",
        setGame,
        enqueueSnackbar,
      });
    }
  };

  return (
    <Box
      className={`hexagon-grid game-board-container ${
        game.decisionStack[0]?.DecisionId === "PLACE_TILE"
          ? "game-board-active"
          : ""
      }`}
      sx={{
        boxSizing: "border-box",
        display: "block",
        width: "650px",
        height: "516px",
        lineHeight: "24px",
        textAlign: "left",
        position: "relative",
      }}
    >
      {game.tiles
        .filter((tile) => {
          return tile.isOnMars;
        })
        .map((tile, index) => (
          <GameBoardTile.Component
            key={index}
            tile={tile}
            onClick={() => tileClicked(tile)}
            style={{
              top: `${(tile.y * 64 * 96) / 112}px`,
              left: `${tile.x * 64 + ((tile.y % 2) * -64) / 2}px`,
            }}
          />
        ))}
      {game.tiles
        .filter((tile) => {
          return !tile.isOnMars;
        })
        .map((tile, index) => (
          <GameBoardTile.Component
            key={index}
            tile={tile}
            onClick={() => tileClicked(tile)}
            style={{
              top: `${index * 80}px`,
              left: "585px",
            }}
          />
        ))}
    </Box>
  );
};

Component.displayName = "GameBoard";
