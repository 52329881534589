import axios from "axios";
import { BASE_URL } from "utils/constants";
import { NotifierFunctionType } from "api/common/notifier";

export const verifyAccount = ({
  key,
  enqueueSnackbar,
}: {
  key: string;
  enqueueSnackbar: NotifierFunctionType;
}) => {
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";

  return axios
    .post("/api/user/verify", {
      key,
    })
    .then(() => {
      enqueueSnackbar("Account verified", { variant: "success" });
      return null;
    })
    .catch((error) => {
      if (error.response?.data?.message) {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("An error occurred", { variant: "error" });
      }
      return null;
    });
};
