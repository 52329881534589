import React, { useContext, useState, useEffect } from "react";
import { getGameLobbies } from "api/game-lobby/get-game-lobby";
import { getActiveGames, Game } from "api/active-games/get-active-games";
import { GameLobbyType } from "api/game-lobby/get-game-lobby";
import * as CreateLobbyModal from "components/pages/browse/CreateLobbyModal";
import { Link as RouterLink } from "react-router-dom";
import { CurrentUser, CurrentUserContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Typography,
  Grid,
  CircularProgress,
  Button,
} from "@mui/material";

export const Component: React.FC<{}> = () => {
  const [activeGames, setActiveGames] = useState<Game[] | null>(null);
  const [lobbies, setLobbies] = useState<GameLobbyType[] | null>(null);
  const [showCreateLobbyModal, setShowCreateLobbyModal] =
    useState<boolean>(false);
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();

  useEffect(() => {
    getActiveGames().then(setActiveGames);
    getGameLobbies().then(setLobbies);
  }, []);

  const lobby = (currentUser: CurrentUser) => {
    return (
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            {renderActiveGames(currentUser._id)}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              gutterBottom
              color="textPrimary"
              style={{ color: "#fff" }}
            >
              Game Lobbies
            </Typography>
            {renderOpenLobbies()}
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowCreateLobbyModal(true)}
            >
              Create Lobby
            </Button>
          </Grid>
        </Grid>
        <CreateLobbyModal.Component
          isOpen={showCreateLobbyModal}
          onClose={() => setShowCreateLobbyModal(false)}
        />
      </Container>
    );
  };
  const renderActiveGames = (currentUserId: string) => {
    if (activeGames === null) {
      return <CircularProgress />;
    }

    return (
      <>
        <Typography
          variant="h5"
          gutterBottom
          color="textPrimary"
          style={{ color: "#fff" }}
        >
          It's Your Turn
        </Typography>
        {renderActiveGamesList(
          activeGames.filter(
            (game) => game.currentDecisions[0]?.UserId === currentUserId
          )
        )}
        <Typography
          variant="h5"
          gutterBottom
          color="textPrimary"
          style={{ color: "#fff" }}
        >
          Other Active Games
        </Typography>
        {renderActiveGamesList(
          activeGames.filter(
            (game) => game.currentDecisions[0]?.UserId !== currentUserId
          )
        )}
      </>
    );
  };

  const renderOpenLobbies = () => {
    if (lobbies === null) {
      return <CircularProgress />;
    }

    if (lobbies.length === 0) {
      return (
        <Typography variant="body1" sx={{ color: "white" }}>
          No active lobbies
        </Typography>
      );
    }

    return <>{lobbies.map(renderOpenLobbyTile)}</>;
  };

  const renderActiveGamesList = (games: Game[]) => {
    if (games.length === 0) {
      return (
        <Typography variant="body1" sx={{ color: "white" }}>
          No active games
        </Typography>
      );
    }

    return games.map(renderActiveGameTile);
  };

  const renderActiveGameTile = (game: Game) => {
    return (
      <Box margin={2}>
        <Card>
          <RouterLink
            to={`/game/${game._id}`}
            style={{ textDecoration: "none" }}
          >
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {game.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Players:{" "}
                  {game.players.map((player) => player.name).join(", ")}
                </Typography>
              </CardContent>
            </CardActionArea>
          </RouterLink>
        </Card>
      </Box>
    );
  };

  const renderOpenLobbyTile = (lobby: GameLobbyType) => {
    return (
      <Box margin={2}>
        <Card>
          <RouterLink
            to={`/lobby/${lobby._id}`}
            style={{ textDecoration: "none" }}
          >
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {lobby.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Created by: {lobby.creator.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </RouterLink>
        </Card>
      </Box>
    );
  };

  const redirectToLogin = () => {
    navigate("/login");
    return <div></div>;
  };

  return <>{currentUser === null ? redirectToLogin() : lobby(currentUser)}</>;
};

Component.displayName = "BrowsePage";
